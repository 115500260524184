import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { useState } from 'react';
import IconCancel from '@mui/icons-material/Cancel';
import { convertFloatToDinero } from '../../utils/money';
import { useNotify, useRefresh } from 'react-admin';
import UserBalanceHandler from '../../react-admin/providers/handlers/UserBalanceHandler';

export const useUserBalancePayDialog = ({ record, hide }: { record: any; hide: () => void }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(record.balance);
  const [description, setDescription] = useState<string>('Repayment');
  const notify = useNotify();
  const refresh = useRefresh();
  const doCharge = () => {
    if (amount == null || isNaN(amount)) {
      notify(`The amount is required`, { type: 'error' });
      return;
    }
    if (description == null || description.trim() === '') {
      notify(`The charge description is required`, { type: 'error' });
      return;
    }
    const dineroAmount = convertFloatToDinero({ amount });
    const dineroBalance = convertFloatToDinero({ amount: record.balance });
    if (dineroAmount.greaterThan(dineroBalance) || dineroAmount.lessThan(convertFloatToDinero({ amount: 1 }))) {
      notify(
        `The amount to charge must must be greater that 1 and not exceed the current balance (${dineroBalance.toFormat()})`,
        {
          type: 'error',
        }
      );
      return;
    }
    setLoading(true);
    UserBalanceHandler.chargeHandler(record.id, dineroAmount.toUnit(), description)
      .then(() => {
        notify(`The customer has been charged`, {
          type: 'success',
        });
        hide();
        refresh();
      })
      .catch((err: any) => {
        notify(typeof err.error === 'object' ? `${err.error.errorMessage}` : `${err.error}: ${err.message}`, {
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    amount,
    setAmount,
    description,
    setDescription,
    doCharge,
    loading,
  };
};
export const UserBalancePayDialog = ({ show, hide, record }: { hide: () => void; show: boolean; record: any }) => {
  const { amount, setAmount, description, setDescription, doCharge, loading } = useUserBalancePayDialog({
    record,
    hide,
  });

  return (
    <Dialog fullWidth open={show} onClose={() => hide()} aria-label="Charge customer">
      <DialogTitle>Charge customer</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              type="number"
              value={amount}
              onChange={(event: any) => setAmount(parseFloat(event.target.value))}
              label={'Amount to charge'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              value={description}
              onChange={(event: any) => setDescription(event.target.value)}
              label={'Description'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => hide()} disabled={loading}>
          <IconCancel />
          <span>Cancel</span>
        </Button>
        <Button disabled={loading} onClick={() => doCharge()}>
          <CurrencyPoundIcon />
          <span>Charge</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
