import { CognitoAuthProviderOptionsIds, CognitoAuthProvider } from 'ra-auth-cognito';
import { AuthProvider } from 'react-admin';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { Access } from '../../utils/access';

const DEFAULT_SCOPES = [
  'openid',
  'email',
  'profile',
  'aws.cognito.signin.user.admin',
  'credit-decision/settings.manage',
  'credit-decision/eligibility.manage',
  'credit-card/user.modify',
];

export const prepareOAuthLoginUrl = (options?: Partial<CognitoAuthProviderOptionsIds>) => {
  const oauthOptions = prepareBaseCognitoAuthProviderOptions(options);

  const redirect_uri = oauthOptions.redirect_uri ?? `${window.location.origin}/auth-callback`;
  const scope = oauthOptions.scope || DEFAULT_SCOPES;
  return `${oauthOptions.hostedUIUrl}/login?client_id=${oauthOptions.clientId}&response_type=token&scope=${scope.join(
    '+'
  )}&redirect_uri=${redirect_uri}`;
};

const prepareBaseCognitoAuthProviderOptions = (
  options?: Partial<CognitoAuthProviderOptionsIds>
): CognitoAuthProviderOptionsIds => {
  return {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    clientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
    mode: 'oauth',
    hostedUIUrl: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_HOSTED_UI!,
    scope: DEFAULT_SCOPES,
    ...options,
  } as CognitoAuthProviderOptionsIds;
};

export const createCognitoAuthProvider = (options?: Partial<CognitoAuthProviderOptionsIds>): AuthProvider => {
  const authProvider = CognitoAuthProvider(prepareBaseCognitoAuthProviderOptions(options));
  return {
    ...authProvider,
    async getPermissions(params: any) {
      return authProvider.getPermissions(params).then((permissions) => {
        return Access(permissions);
      });
    },
    async checkError(error: any) {
      if (error && (error.status === 401 || error.status === 403)) {
        throw new Error('Unauthorized');
      }
    },
  };
};

export const getUserPool = (): CognitoUserPool => {
  return new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
  });
};

export const getCognitoAccessToken = async (): Promise<string | null> => {
  const userPool = getUserPool();
  const user = userPool.getCurrentUser();

  if (user) {
    return new Promise((resolve): void => {
      user.getSession((error: Error | null, session: CognitoUserSession | null) => {
        if (error || !session || !session.isValid()) {
          return resolve(null);
        }
        return resolve(session?.getAccessToken().getJwtToken() ?? null);
      });
    });
  }

  return null;
};

export const getUserId = async (): Promise<string | null> => {
  const userPool = getUserPool();

  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) {
      return reject();
    }
    user.getSession((err: any, session: { isValid: () => any }) => {
      if (err) {
        return reject(err);
      }
      if (!session.isValid()) {
        return reject();
      }
      user.getUserAttributes((err, attributes) => {
        if (err) {
          return reject(err);
        }

        resolve(attributes?.find((attribute) => attribute.Name === 'custom:bitsUserId')?.Value ?? null);
      });
    });
  });
};

export const useGetUserId = (): string | null => {
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    getUserId()
      .then((userId) => setUserId(userId))
      .catch(() => setUserId(null));
  }, []);

  return userId;
};
