import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getJson, postJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';
import UserManagementHandler from './UserManagementHandler';

const getChangedData = (data: Record<string, any>, previousData: Record<string, any>): Record<string, any> => {
  const changedData: Record<string, any> = {};

  for (const key in data) {
    if (data[key] === null && data[key] !== previousData[key]) {
      changedData[key] = data[key];
      continue;
    }

    if (typeof data[key] === 'object' && key !== 'comments') {
      continue;
    }

    if (data[key] !== previousData[key]) {
      changedData[key] = data[key];
      continue;
    }
  }

  return changedData;
};

export default class UserHandler implements IHandler {
  static route = '/admin/user';
  static resourceIdName = 'userId';
  static prevNextToken: Record<number, string> = {};

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      pageSize: params?.pagination.perPage ?? 10,
      ...params?.filter,
    };

    if (query.email || query.phoneNumber) {
      return UserHandler.searchInUserManagement(params);
    }

    const page = params?.pagination.page ?? 1;
    let startIdx = 1;
    if (page > 1 && UserHandler.prevNextToken[page - 1] != null) {
      query['LastEvaluatedKey'] = UserHandler.prevNextToken[page - 1];
      startIdx = (page - 1) * (params?.pagination.perPage ?? 10);
    }

    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`).then(async (response) => {
      let { data: responseData } = await response.json();

      const lastEvaluatedKey = responseData?.LastEvaluatedKey;
      UserHandler.prevNextToken[page] = JSON.stringify(lastEvaluatedKey);

      responseData = responseData.Items ?? responseData;
      responseData = responseData?.length > 0 ? responseData : [];

      let idx = startIdx;

      responseData = responseData.map((data: any) => {
        const result = {
          idx,
          ...data,
        };

        idx++;

        result.id = data[this.resourceIdName];
        delete result[this.resourceIdName];

        return {
          ...result,
        };
      });

      return {
        data: responseData,
        pageInfo: {
          hasNextPage: !!lastEvaluatedKey,
          nextPageToken: lastEvaluatedKey ?? undefined,
        },
      };
    });
  }

  static async searchInUserManagement<RecordType extends RaRecord = any>(
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    return UserManagementHandler.getListHandler('', params).then(async (response) => {
      if (response.data.length === 0) {
        return {
          data: [],
          pageInfo: response.pageInfo,
        } as GetListResult<RecordType>;
      }

      const records = await Promise.all(
        response.data.map(async (data: any) => {
          const { id: userId } = data;

          return UserHandler.getOneHandler('', { id: userId })
            .then((response) => response.data)
            .catch(() => null);
        })
      );

      return {
        data: records.filter((record: any) => record != null),
        pageInfo: response.pageInfo,
      };
    });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}/${id}`).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];

      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const id = params?.id;
    const payload = params?.data || {};
    const previousPayload = params?.previousData || {};
    const changedData = getChangedData(payload, previousPayload);

    return putJson(`${this.route}/${id}`, changedData)
      .then(async (response) => {
        const { data } = await response.json();

        data.id = data[this.resourceIdName];
        delete data[this.resourceIdName];

        return {
          data: {
            ...data,
            id: '',
          },
        };
      })
      .catch((response) => {
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response?.error?.errorMessage ?? response.statusText,
        });
      });
  }

  static async deactivateSubscription(
    userId: string,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return postJson(`${this.route}/${userId}/subscription/deactivate`, { reason })
      .then(async (response) => {
        const { data } = await response.json();

        const d = data;
        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];
        onSuccess();
        return {
          data: {
            ...d,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
  static async unlock(userId: string, reason: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return putJson(`${this.route}/${userId}/unlock`, { reason })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async unfreeze(userId: string, reason: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return putJson(`${this.route}/${userId}/unfreeze`, { reason })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async setCreditLimit(
    userId: string,
    newCreditLimit: number,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return putJson(`${this.route}/${userId}/credit-limit`, { newCreditLimit })
      .then(async (response) => {
        await response.json();
        onSuccess();
        return {
          data: {
            id: userId,
          },
        };
      })
      .catch((response) => {
        onFailure(
          response.error?.errorMessage ??
            `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${
              response.message
            }`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async pauseCollections(
    userId: string,
    date: string,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return putJson(`${this.route}/${userId}/collections-pause`, { userId, date, reason })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          response.error?.errorMessage ??
            `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${
              response.message
            }`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async waiveBalance(
    userId: string,
    amount: number,
    blockSignup: boolean,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return putJson(`${this.route}/${userId}/waive-balance`, { userId, reason, amount, blockSignup })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          response.error?.errorMessage ??
            `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${
              response.message
            }`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async unPauseCollections(
    userId: string,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return putJson(`${this.route}/${userId}/collections-unpause`, { userId, reason })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          response.error?.errorMessage ??
            `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${
              response.message
            }`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
