import { createDateRenderer } from '../../utils/misc';

import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  TextField,
  useRecordContext,
} from 'react-admin';
import { HistoryEventType } from '../../utils/models';
import { Link } from 'react-router-dom';
import { CURRENCY } from '../../utils/money';
import React from 'react';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} />;

const getFilters = () => {
  return [
    <ReferenceInput source="userId" reference="usermanagement" filter={{ searchOnly: true }} label="ChangedBy" alwaysOn>
      <AutocompleteInput filterToQuery={(t: string) => ({ email: t })} optionText={'email'} sx={{ width: 300 }} />
    </ReferenceInput>,
    <DateInput source="from" label="After" alwaysOn />,
    <DateInput source="to" label="Before" alwaysOn />,
  ];
};

const UserNameLinkField = (props: any) => {
  const record = useRecordContext(props);
  const { firstName, lastName, email, membershipNumber } = record;

  const userShowPage = `/users/${membershipNumber}/show`;
  return (
    <Link to={userShowPage}>
      <span title={email}>
        {firstName} {lastName}
      </span>
    </Link>
  );
};

const RepaymentHistoryList = () => {
  return (
    <List
      resource={'history'}
      actions={false}
      filters={getFilters()}
      perPage={25}
      pagination={<PostPagination />}
      queryOptions={{
        refetchInterval: false,
        meta: {
          eventTypes: [
            HistoryEventType.AdminManualCharge,
            HistoryEventType.AdminManualChargeFailed,
            HistoryEventType.UserCharge,
            HistoryEventType.UserChargeFailed,
            HistoryEventType.ChargeOnPaymentDate,
            HistoryEventType.ChargeOnPaymentDateFailed,
          ],
        },
      }}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
        <TextField source="eventType" />
        <NumberField
          source="payload.amount"
          label={'Amount'}
          sortable={false}
          options={{ style: 'currency', currency: CURRENCY }}
        />
        <ReferenceField reference={'usermanagement'} source={'userId'} label={'User'}>
          <UserNameLinkField />
        </ReferenceField>
        <ReferenceField reference={'usermanagement'} source={'initiator'} label={'ChargedBy'}>
          <UserNameLinkField />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default RepaymentHistoryList;
