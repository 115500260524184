import {
  DataProvider as RaDataProvider,
  CreateParams,
  CreateResult,
  DeleteManyResult,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
  GetManyParams,
  GetManyReferenceParams,
} from 'react-admin';

import { putJson, getJson, postJson } from '../../utils/api';
import AdminConfigHandler from './handlers/AdminConfigHandler';
import CardsHandler from './handlers/CardsHandler';
import TransactionsHandler from './handlers/TransactionsHandler';
import UserHandler from './handlers/UserHandler';
import UserBalanceHandler from './handlers/UserBalanceHandler';
import StatsHandler from './handlers/StatsHandler';
import MccHandler from './handlers/MccHandler';
import UserManagementHandler from './handlers/UserManagementHandler';
import AdminConfigCreditDecisionHandler from './handlers/AdminConfigCreditDecisionHandler';
import CreditDecisionApplicationHandler from './handlers/CreditDecisionApplicationHandler';
import HistoryHandler from './handlers/HistoryHandler';
import Phone3DSHandler from './handlers/Phone3DSHandler';
import WaitlistHandler from './handlers/WaitlistHandler';
import UserTransactionsHandler from './handlers/UserTransactionsHandler';

const resourceHandlerMap = new Map<string, any>([
  ['config', AdminConfigHandler],
  ['users', UserHandler],
  ['cards', CardsHandler],
  ['transactions', TransactionsHandler],
  ['balance', UserBalanceHandler],
  ['stats', StatsHandler],
  ['mcc', MccHandler],
  ['usermanagement', UserManagementHandler],
  ['creditdecisionconfig', AdminConfigCreditDecisionHandler],
  ['creditdecisionapplications', CreditDecisionApplicationHandler],
  ['history', HistoryHandler],
  ['phone3ds', Phone3DSHandler],
  ['waitlist', WaitlistHandler],
  ['usertransactions', UserTransactionsHandler],
]);

const getResourceName = (resource: string): string => {
  const resourceComponents = resource.split('/');
  return resourceComponents.length > 0 ? resourceComponents[resourceComponents.length - 1] : resource;
};

class DataProvider implements RaDataProvider {
  async getList<RecordType extends RaRecord = any>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getListHandler) {
      return handler.getListHandler(resource, params);
    }

    return getJson(`/${resource}`).then(async (response) => {
      let { data: responseData } = await response.json();

      responseData = responseData.Items ?? responseData;
      responseData = responseData?.length > 0 ? responseData : [];

      return {
        data: responseData,
        total: responseData.length,
      };
    });
  }

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const { id } = params;
    params.id = id === 'undefined' ? '' : id;

    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getOneHandler) {
      return handler.getOneHandler(resource, params);
    }

    return getJson(`/${resource}/${id}`).then(async (response) => {
      const { data } = await response.json();
      return {
        data,
      };
    });
  }

  async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getMany) {
      return handler.getMany(resource, params);
    }

    throw new Error('Method not available');
  }

  async getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.getManyReference) {
      return handler.getManyReference(resource, params);
    }

    return Promise.reject(new Error('Method not available'));
  }

  async update<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const { id } = params;
    params.id = id === 'undefined' ? '' : id;

    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.updateHandler) {
      return handler.updateHandler(resource, params);
    }
    const payload = params.data;

    return putJson(`/${resource}/${id}`, payload).then(async (response) => {
      const { data } = await response.json();

      return {
        data,
      };
    });
  }

  // this should update an array of ids, the api doesn't have this feature
  async updateMany<RecordType extends RaRecord = any>(): Promise<UpdateManyResult<RecordType>> {
    return Promise.reject(new Error('Method not available'));
  }

  async create<RecordType extends RaRecord = any>(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params.data;

    const resourceName = getResourceName(resource);
    const handler = resourceHandlerMap.get(resourceName);
    if (handler?.createHandler) {
      return handler.createHandler(resource, params);
    }

    return postJson(`/${resource}`, payload).then(async (response) => {
      const { data } = await response.json();

      return {
        data,
      };
    });
  }

  // the api doesn't have any delete method yet
  async delete<RecordType extends RaRecord = any>(): Promise<DeleteResult<RecordType>> {
    return Promise.reject(new Error('Method not available'));
  }

  // this should delete an array of ids, the api doesn't have this feature
  async deleteMany<RecordType extends RaRecord = any>(): Promise<DeleteManyResult<RecordType>> {
    return Promise.reject(new Error('Method not available'));
  }
}

export default new DataProvider();
