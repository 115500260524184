import { GetListResult, GetOneResult, RaRecord, UpdateParams, UpdateResult } from 'react-admin';
import { getJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class AdminConfigHandler implements IHandler {
  static route = '/admin/config';
  static resourceIdName = 'apiConfigId';

  static async getListHandler<RecordType extends RaRecord = any>(): Promise<GetListResult<RecordType>> {
    return getJson(`${this.route}`).then(async (response) => {
      let { data: responseData } = await response.json();

      responseData = responseData.Items ?? responseData;
      responseData = responseData?.length > 0 ? responseData : [];

      responseData = responseData.map((data: any) => {
        const result = {
          ...data,
        };

        result.id = data[this.resourceIdName];
        delete result[this.resourceIdName];

        return {
          ...result,
        };
      });

      return {
        data: responseData,
        total: responseData.length,
      };
    });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(): Promise<GetOneResult<RecordType>> {
    return getJson(`${this.route}`).then(async (response) => {
      const { data } = await response.json();

      return {
        data: {
          ...data,
          id: '',
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const payload = params?.data || {};
    return putJson(`${this.route}`, payload).then(async (response) => {
      const { data } = await response.json();

      return {
        data: {
          ...data,
          id: '',
        },
      };
    });
  }
}
