import Dinero, { Currency } from 'dinero.js';

export interface MoneyId {
  amount: number;
  currency?: string;
}

export const convertFloatToDinero = (item: MoneyId) => {
  const amountInMinimalUnit = Math.round(item.amount * Math.pow(10, Dinero.defaultPrecision));
  const currency = (item.currency?.toUpperCase() as Dinero.Currency) || Dinero.defaultCurrency;

  return Dinero({
    amount: amountInMinimalUnit,
    currency: currency,
  });
};

export const CURRENCY = (process.env.REACT_APP_CURRENCY ?? 'GBP') as Currency;

Dinero.defaultCurrency = CURRENCY;
Dinero.defaultPrecision = 2;
