export interface IAccess {
  DashboardView: () => boolean;
  UserSearchView: () => boolean;
  UserSearchExport: () => boolean;
  UserDetailsView: () => boolean;
  UserDetailsEdit: () => boolean;
  UserDetailsCharge: () => boolean;
  UserDetailsCancel: () => boolean;
  UserDetailsSyncPHONE: () => boolean;
  UserDetailsChangeCreditLimit: () => boolean;
  CardListView: () => boolean;
  CardListExport: () => boolean;
  CardListCreate: () => boolean;
  TransactionListView: () => boolean;
  TransactionListExport: () => boolean;
  ApplicationSearchView: () => boolean;
  ApplicationDetailsView: () => boolean;
  RepaymentSearchView: () => boolean;
  SettingsDetailsView: () => boolean;
  SettingsDetailsEdit: () => boolean;
  WaitlistEdit: () => boolean;
  MCCSearchView: () => boolean;
  ActivitySearchView: () => boolean;
  TasksAdmin: () => boolean;
}

export const Access = (permissions: string[] | undefined): IAccess => {
  const all = () =>
    permissions?.includes(`credit-card-support-admin`) ||
    permissions?.includes(`credit-card-admin`) ||
    permissions?.includes(`super-admin`) ||
    false;
  const admins = () => permissions?.includes(`credit-card-admin`) || permissions?.includes(`super-admin`) || false;
  const superAdmin = () => permissions?.includes(`super-admin`) || false;

  return {
    DashboardView: all,
    UserSearchView: all,
    UserSearchExport: admins,
    UserDetailsCharge: admins,
    UserDetailsView: admins,
    UserDetailsEdit: admins,
    UserDetailsCancel: admins,
    UserDetailsSyncPHONE: admins,
    UserDetailsChangeCreditLimit: admins,
    CardListView: all,
    CardListExport: admins,
    TransactionListView: all,
    TransactionListExport: admins,
    ApplicationSearchView: all,
    ApplicationDetailsView: all,
    RepaymentSearchView: all,
    SettingsDetailsView: admins,
    SettingsDetailsEdit: superAdmin,
    MCCSearchView: all,
    WaitlistEdit: admins,
    ActivitySearchView: superAdmin,
    TasksAdmin: superAdmin,
    CardListCreate: admins,
  };
};
