import dayjs from 'dayjs';

export const createDateRenderer = (column: string) => (record: any) =>
  record[column] && dayjs(record[column]).format('YYYY-MM-DD HH:mm');

export const getChangedData = (data: Record<string, any>, previousData: Record<string, any>): Record<string, any> => {
  const changedData: Record<string, any> = {};

  for (const key in data) {
    if (data[key] === null && data[key] !== previousData[key]) {
      changedData[key] = data[key];
      continue;
    }

    if (typeof data[key] === 'object') {
      continue;
    }

    if (data[key] !== previousData[key]) {
      changedData[key] = data[key];
      continue;
    }
  }

  return changedData;
};
