import { GetListParams, GetListResult, GetOneParams, GetOneResult, RaRecord } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class CreditDecisionApplicationHandler implements IHandler {
  static route = '/admin/eligibility';
  static resourceIdName = 'eligibilityId';

  static prevNextToken: Record<number, string> = {};

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
      ...params?.filter,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1) {
      query.nextToken = CreditDecisionApplicationHandler.prevNextToken[page - 1];
    }

    for (const param in query) {
      if (query[param] === undefined || query[param] === null || query[param] === '') {
        delete query[param];
      }
    }

    return postJson(`${this.route}/search`, query, process.env.REACT_APP_BASE_CREDIT_DECISION_API_URL).then(
      async (response) => {
        let { data: responseData } = await response.json();

        const nextToken = responseData.nextToken;
        if (nextToken) {
          CreditDecisionApplicationHandler.prevNextToken[page] = nextToken;
        }

        responseData = responseData?.items || [];

        responseData = responseData.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];

          return {
            ...result,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      }
    );
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}/${id}`, process.env.REACT_APP_BASE_CREDIT_DECISION_API_URL).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];

      return {
        data: {
          ...data,
        },
      };
    });
  }
}
