import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { useResourceAccess } from '../../../hooks/useAccess';
import TasksHandler from '../../../react-admin/providers/handlers/TasksHandler';

const useMarketingTaskAction = ({ setShow }: { setShow: (flag: boolean) => void }) => {
  const [loading, setLoading] = useState(false);
  const [chunkSize, setChunkSize] = useState(100);
  const [inviteExpireDays, setInviteExpireDays] = useState(7);

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setChunkSize(100);
    setInviteExpireDays(7);
    setLoading(false);
    setShow(false);
  };
  const startTask = async () => {
    setLoading(true);
    TasksHandler.startTask(
      'marketing',
      { chunkSize, inviteExpireDays },
      () => {
        refresh();
        close();
      },
      (e: any) => {
        notify(e.toString(), { type: 'error' });
        close();
      }
    );
  };

  return {
    inviteExpireDays,
    setInviteExpireDays: (event: any) => setInviteExpireDays(parseInt(event.target.value, 10)),
    chunkSize,
    setChunkSize: (event: any) => setChunkSize(parseInt(event.target.value)),
    startTask,
    close,
    loading,
  };
};

export const MarketingTaskAction = () => {
  const { TasksAdmin } = useResourceAccess();
  const isRo = !TasksAdmin();

  const [show, setShow] = useState(false);
  const { startTask, close, loading, chunkSize, setChunkSize, inviteExpireDays, setInviteExpireDays } =
    useMarketingTaskAction({
      setShow,
    });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)} disabled={isRo} variant={'outlined'}>
        Create Marketing Task
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Create Marketing Task</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span>
                <b>Attention!</b>
                <br />
                <br />
                You are about to create mass marketing email chunk.
                <br />
                <br />
                You will receive email notification once the task is completed.
                <br />
                <br />
                Are you sure you want to proceed?
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                // title={'Chunk size'}
                label={'Chunk size'}
                id="chunkSize"
                value={chunkSize}
                onChange={setChunkSize}
                type={'number'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={'Invite Expire Days'}
                id="inviteExpireDays"
                value={inviteExpireDays}
                onChange={setInviteExpireDays}
                type={'number'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button onClick={startTask} disabled={!chunkSize || !inviteExpireDays || loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
