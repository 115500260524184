import IHandler from './IHandler';
import { GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';

export default class StatsHandler implements IHandler {
  static route = '/admin/stats';
  static resourceIdName = 'timestamp';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      pageSize: params?.pagination.perPage ?? 10,
    };

    if (params?.filter?.dateFrom) {
      query.dateFrom = params?.filter?.dateFrom;
    }

    if (params?.filter?.dateTo) {
      query.dateTo = params?.filter?.dateTo;
    }

    if (params?.filter.LastEvaluatedKey) {
      query['LastEvaluatedKey'] = params?.filter.LastEvaluatedKey;
    }
    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`)
      .then(async (response) => {
        let { data: responseData } = await response.json();

        const nextToken = responseData.nextToken;
        responseData = responseData?.rows || [];

        responseData = responseData.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];

          const { totalBalanceOutstanding = 0, totalOfCreditLimitApproved = 0 } = result;

          let utilization = totalBalanceOutstanding / totalOfCreditLimitApproved;
          if (utilization > 1) {
            utilization = 1;
          } else if (isNaN(utilization)) {
            utilization = 0;
          }

          return {
            ...result,
            utilization,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
            nextPageToken: nextToken ?? undefined,
          },
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject();
      });
  }
}
