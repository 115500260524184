import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  NumberField,
  Pagination,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useGetOne,
  useRecordContext,
  useShowContext,
} from 'react-admin';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import LinkToCards from '../LinkToCards';
import { UserModel, UserStatus } from '../../utils/models';
import { DeactivateAccountAction } from '../DeactivateAccountAction';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { UserBalancePayDialog } from '../UserBalanceCharge';
import { CURRENCY } from '../../utils/money';
import { CustomReferenceField } from '../CustomReferenceField';
import { DEFAULT_QUERY_OPTIONS } from '../../utils/query-util';
import { Sync3DSAction } from '../Sync3DSAction';
import { SetCreditLimitAction } from '../SetCreditLimitAction';
import { createDateRenderer } from '../../utils/misc';
import { CurrencyMapping } from '../../utils/currency';
import { UnlockUserAction } from '../UnlockUserAction';
import { UnfreezeUserAction } from '../UnfreezeUserAction';
import { useResourceAccess } from '../../hooks/useAccess';
import { UnPauseCollectionsAction } from '../UnPauseCollectionsAction';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { WeeklyPaymentDays } from '../UsersEdit';
import { WaiveBalanceAction } from '../WaiveBalanceAction';
import { PauseCollectionsAction } from '../PauseCollectionsAction';
import { CommentsView } from '../CommentsView';

dayjs.extend(utc);
dayjs.extend(tz);

const NestedUserBalanceDetails = () => {
  const { record } = useShowContext();
  const { data, isLoading } = useGetOne(
    `balance`,
    { id: record?.id },
    {
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <RecordContextProvider value={data}>
      <SimpleShowLayout>
        <NumberField source="balance" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="minPayment" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="subscriptionFee" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="lateFee" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="spendBalance" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="totalFee" options={{ style: 'currency', currency: CURRENCY }} />
      </SimpleShowLayout>
      <ChargeButton />
      {data.balance > 0 && <WaiveBalanceAction user={record} amount={data.balance}></WaiveBalanceAction>}
    </RecordContextProvider>
  );
};

const UserActions = () => {
  const { UserDetailsCancel, UserDetailsEdit, UserDetailsSyncPHONE, UserDetailsChangeCreditLimit } =
    useResourceAccess();

  const record = useRecordContext<UserModel>();

  const actions = [];
  if (record != null) {
    actions.push(<LinkToCards />);
    if (UserDetailsEdit()) {
      actions.push(<EditButton key="edit" />);
    }
    if ([UserStatus.active, UserStatus.new, UserStatus.pending].includes(record.status)) {
      if (record.collectionsPausedUntil != null && record.collectionsPausedUntil > new Date().toISOString()) {
        actions.push(<UnPauseCollectionsAction key="unpause" user={record} />);
      } else {
        if (record.isLocked || record.isDelinquent || record.isFrozen || record.isDefault) {
          actions.push(<PauseCollectionsAction key="pause" user={record} />);
        }
      }
      if (record.isLocked) {
        actions.push(<UnlockUserAction key="unlock" user={record} />);
      }
      if (record.isFrozen) {
        actions.push(<UnfreezeUserAction key="unfreeze" user={record} />);
      }
      if (UserDetailsCancel()) {
        actions.push(<DeactivateAccountAction key="deactivate" user={record} />);
      }
      if (UserDetailsSyncPHONE()) {
        actions.push(<Sync3DSAction key="sync" user={record} />);
      }
      if (UserDetailsChangeCreditLimit()) {
        actions.push(<SetCreditLimitAction key="setCreditLimit" user={record} />);
      }
    }
  }

  return <TopToolbar>{actions}</TopToolbar>;
};

const ChargeButton = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const record = useRecordContext();
  const { UserDetailsCharge } = useResourceAccess();

  const showCharge = UserDetailsCharge() && record?.balance > 0;
  return (
    <>
      {showCharge && (
        <Button
          startIcon={<CurrencyPoundIcon />}
          label={'Charge'}
          title={'Charge balance'}
          onClick={() => {
            setShowDialog(true);
          }}
        ></Button>
      )}
      <UserBalancePayDialog record={record} show={showDialog} hide={() => setShowDialog(false)} />
    </>
  );
};
const amountRenderer = (record: any) => {
  if (record.amount == null) {
    return '';
  }
  const billCurrency: string = record.provider?.Bill_Ccy ?? '826';
  const txnCurrency: string = record.provider?.Txn_CCy ?? '826';
  const isSameCurrency = billCurrency === txnCurrency;

  let billAmount = `${Math.abs(record.amount)} ${CurrencyMapping[billCurrency]}`;
  let txnAmount = `${Math.abs(record.provider.Txn_Amt)} ${CurrencyMapping[txnCurrency]}`;
  if (record.type === 'payment' || record.type === 'transaction-type-card-receive') {
    if (record.amount !== 0) {
      billAmount = `+${billAmount}`;
      txnAmount = `+${txnAmount}`;
    }
  } else {
    // if (record.type === 'transaction-type-card-reserve') {
    if (record.amount !== 0) {
      billAmount = `-${billAmount}`;
      txnAmount = `-${txnAmount}`;
    }
    // }
  }
  if (record.status === 'approved') {
    return <div style={{ color: 'green' }}>{billAmount}</div>;
  }

  if (!isSameCurrency) {
    if (record.status === 'transaction-status-card-reservation-declined') {
      return (
        <div style={{ textDecoration: 'line-through' }}>
          <div style={{ color: 'red' }}>{billAmount}</div>
          <div style={{ color: 'red' }}>{txnAmount}</div>
        </div>
      );
    }

    return (
      <div>
        <div>{billAmount}</div>
        <div>{txnAmount}</div>
      </div>
    );
  } else {
    if (record.status === 'transaction-status-card-reservation-declined') {
      return <div style={{ color: 'red', textDecoration: 'line-through' }}>{billAmount}</div>;
    }

    if (record.status === 'transaction-status-card-reservation-released') {
      return <div style={{ textDecoration: 'line-through' }}>{billAmount}</div>;
    }

    return <div>{billAmount}</div>;
  }
};
const merchantRenderer = (record: any) => {
  if (record.description == null) {
    return '';
  }
  const merchant = record.description;

  if (record.status === 'approved') {
    return <div style={{ color: 'green' }}>{merchant}</div>;
  }

  if (record.status === 'transaction-status-card-reservation-declined') {
    return (
      <div>
        <div style={{ color: 'red' }}>{merchant}</div>
        <div style={{ color: 'red' }}>{record.declineReason}</div>
      </div>
    );
  }

  if (record.status === 'transaction-status-card-reservation-approved') {
    return (
      <div>
        <div>{merchant}</div>
        <div>Pending</div>
      </div>
    );
  }

  if (record.status === 'transaction-status-card-reservation-released') {
    return (
      <div>
        <div>{merchant}</div>
        <div>Reversed</div>
      </div>
    );
  }

  if (record.status === 'transaction-status-approved') {
    return (
      <div>
        <div>{merchant}</div>
        <div>{record.type === 'transaction-type-card-receive' ? 'Refunded' : 'Paid'}</div>
      </div>
    );
  }

  return (
    <div>
      <div>{merchant}</div>
    </div>
  );
};

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} defaultValue={25} />;

export const UsersDetails = () => {
  const { data } = useGetOne('config', {
    id: '',
  });

  return (
    <Show actions={<UserActions />}>
      <Grid container>
        <Grid container xs={10} spacing={2}>
          <Grid item xs={4}>
            <SimpleShowLayout>
              <TextField source="id" />
              <CustomReferenceField reference={'usermanagement'} source={'id'} label={'FirstName'}>
                <TextField source="firstName" />
              </CustomReferenceField>
              <CustomReferenceField reference={'usermanagement'} source={'id'} label={'LastName'}>
                <TextField source="lastName" />
              </CustomReferenceField>
              <CustomReferenceField reference={'usermanagement'} source={'id'} label={'DateOfBirth'}>
                <TextField source="dateBirth" />
              </CustomReferenceField>
              <CustomReferenceField reference={'usermanagement'} source={'id'} label={'Email'}>
                <EmailField source="email" />
              </CustomReferenceField>
              <CustomReferenceField reference={'usermanagement'} source={'id'} label={'Phone'}>
                <EmailField source="phoneNumber" />
              </CustomReferenceField>
              <CustomReferenceField reference={'phone3ds'} source={'id'} label={'3DS phone number'}>
                <EmailField source="phone" />
              </CustomReferenceField>
              <TextField source="status" />
              <TextField source="isAdmin" />
              <DateField source="subscriptionStartDate" label="Subscription Start Date" />
              <DateField source="nextBillingDate" label="Next Billing Date" />
              <DateField source="nextPaymentDate" label="Next Payment Date" />
              <NumberField
                source="creditLimit"
                label="Credit Limit"
                options={{ style: 'currency', currency: CURRENCY }}
              />
              <NumberField
                source="ledgerBalance"
                label="Available"
                options={{ style: 'currency', currency: CURRENCY }}
              />
              <NumberField
                source="totalPayoff"
                label={<span title="Total amount repaid">Payoff</span>}
                options={{ style: 'currency', currency: CURRENCY }}
              />
              <NumberField
                source="lastUtilization"
                label="Utilization"
                sortable={false}
                options={{ style: 'percent' }}
              />
              <BooleanField source="isAutoPaymentOn" label="Is Auto Payment On?" defaultValue={data?.autoPaymentOn} />
              <BooleanField source="isLocked" label="Is Locked?" />
              <BooleanField source="isFrozen" label="Is Frozen?" />
              <BooleanField source="isDelinquent" label="Is Delinquent?" />
              <BooleanField source="isDefault" label="Is Default?" />
              <DateField source="dateCreate" label="Created" />
              <FunctionField
                source="collectionsPausedUntil"
                render={(record: UserModel) => {
                  if (
                    record.collectionsPausedUntil == null ||
                    record.collectionsPausedUntil < new Date().toISOString()
                  ) {
                    return '';
                  }
                  return dayjs.utc(record.collectionsPausedUntil).add(-1, 'seconds').format('YYYY-MM-DD HH:mm');
                }}
              />
              <FunctionField
                source="weeklyPaymentDay"
                render={(record: UserModel) =>
                  WeeklyPaymentDays.find((d) => d.id === record.weeklyPaymentDay)?.name ?? 'Tuesday'
                }
              />
              <BooleanField source="disabledReporting" />
              <BooleanField source="isFirstPreAuthSucceeded" />
              <BooleanField source="isPostDelinquencyPreAuthSucceeded" />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={2}>
            <NestedUserBalanceDetails></NestedUserBalanceDetails>
          </Grid>
          <Grid item xs={6}>
            <CommentsView source="comments" />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <ReferenceManyField reference={'history'} target={'userId'} label="Events" pagination={<PostPagination />}>
            <Datagrid bulkActionButtons={false}>
              <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
              <TextField source="eventMessage" />
              <ReferenceField reference={'usermanagement'} source={'initiator'} label={'ChangedBy'}>
                <TextField source="email" />
              </ReferenceField>
              <TextField source="payload.reason" label={'Reason'} />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField reference={'usertransactions'} target={'userId'} label="Transactions">
            <Datagrid bulkActionButtons={false}>
              <FunctionField source="createdAt" render={createDateRenderer('createdAt')} label={'Created'} />
              <TextField source="id" />
              <FunctionField source="description" render={merchantRenderer} />
              <FunctionField source="amount" render={amountRenderer} />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
      </Grid>
    </Show>
  );
};
