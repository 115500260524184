import { Datagrid, List, TextField, TextInput } from 'react-admin';

const filters = [<TextInput source="search" alwaysOn />];

export const MccList = () => {
  return (
    <List resource={`mcc`} title="MCC" hasCreate={true} filters={filters}>
      <Datagrid>
        <TextField source="id" label="MCC" />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};
