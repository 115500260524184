import {
  BooleanField,
  ChipField,
  DateField,
  EmailField,
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
} from 'react-admin';
import { CURRENCY } from '../../utils/money';
import UserLink from '../UserLink';

export const CreditDecisionAppDetails = () => {
  return (
    <Show title={'Application'}>
      <SimpleShowLayout>
        <DateField source="dateCreated" label="Date Applied" showTime={false} sortable={false} />
        <UserLink source="speedboatUserId" label="UserId" sortable={false} />
        <TextField source="title" label="Title" sortable={false} />
        <TextField source="firstName" label="FirstName" sortable={false} />
        <TextField source="lastName" label="LastName" sortable={false} />
        <EmailField source="email" label="Email" sortable={false} />
        <TextField source="phoneNumber" />
        <DateField source="birthdate" />

        <NumberField
          source="monthlyIncome"
          label="Monthly Income"
          options={{ style: 'currency', currency: CURRENCY }}
        />
        <NumberField
          source="monthlyExpenses"
          label="Monthly Expenses"
          options={{ style: 'currency', currency: CURRENCY }}
        />
        <BooleanField source="monthlyIncomeError" label="Monthly Income Error" sortable={false} />
        <BooleanField source="monthlyExpensesError" label="Monthly Expenses Error" sortable={false} />
        <NumberField source="monthlyFee" />

        <ChipField source="status" label="Status" sortable={false} />
        <TextField source="step" label="Step" sortable={false} />
        <BooleanField source="activeDefaults" />
        <BooleanField source="activeDefaultsError" />
        <TextField source="address1" />
        <TextField source="address2" />
        <TextField source="city" />
        <TextField source="placeId" />
        <TextField source="postCode" />
        <BooleanField source="addressVerified" />

        <NumberField source="apr" />
        <BooleanField source="cashAdvance" />
        <BooleanField source="cashAdvanceError" />
        <BooleanField source="ccj" />
        <BooleanField source="ccjError" />

        <NumberField source="creditLimit" options={{ style: 'currency', currency: CURRENCY }} />
        <BooleanField source="debtStrugglingOrDMP" />
        <BooleanField source="debtStrugglingOrDMPError" />
        <TextField source="employmentStatus" />
        <BooleanField source="employmentStatusError" />
        <NumberField source="expectedExpenditure" />
        <BooleanField source="financialDetailsConfirmation" />
        <BooleanField source="hardcutsFailed" />
        <BooleanField source="hasLatePaymentsWithBits" />
        <BooleanField source="ivaBankruptcy" />
        <BooleanField source="ivaBankruptcyError" />
        <BooleanField source="lateOnPayments" />
        <BooleanField source="lateOnPaymentsError" />
        <TextField source="membershipNumber" />
        <NumberField source="numberOfPeopleRelying" />
        <BooleanField source="numberOfPeopleRelyingError" />
        <TextField source="referral" />
        <DateField source="dateUpdated" label="Date Modified" sortable={false} />
      </SimpleShowLayout>
    </Show>
  );
};
