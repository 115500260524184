import { EditButton, NumberField, Show, SimpleShowLayout, useCreatePath } from 'react-admin';
import { useGetRecordId } from 'react-admin';

const UserBalanceActions = ({ userId }: { userId: string }) => {
  const createPath = useCreatePath();

  return (
    <EditButton
      resource={'balance'}
      to={createPath({ resource: 'balance', type: 'edit', id: userId })}
      label={'Adjust'}
    ></EditButton>
  );
};

export const UsersBalanceDetails = () => {
  const userId = useGetRecordId();
  return (
    <Show resource="balance" actions={<UserBalanceActions userId={userId as string} />}>
      <SimpleShowLayout>
        <NumberField source="balance" />
        <NumberField source="minPayment" />
        <NumberField source="subscriptionFee" />
        <NumberField source="lateFee" />
        <NumberField source="spendBalance" />
        <NumberField source="totalFee" />
      </SimpleShowLayout>
    </Show>
  );
};
