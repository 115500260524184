import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListButton,
  Loading,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { useQuery } from '../../hooks/useQuery';
import { CardModel, CardPrintStatus } from '../../utils/models';
import dayjs from 'dayjs';
import { CancelCardAction } from '../CancelCardAction';
import ImageEye from '@mui/icons-material/RemoveRedEye';
import { CardPinRetriesResetAction } from '../CardPinRetriesResetAction';
import { useResourceAccess } from '../../hooks/useAccess';
import { CardCreateAction } from '../CardCreateAction';

const CardsListActions = () => {
  const { CardListCreate } = useResourceAccess();
  const userId = useQuery().get('userId');

  if (!CardListCreate()) {
    return <></>;
  }

  if (!userId) {
    console.warn('userId is not defined');
    return <></>;
  }

  return (
    <TopToolbar>
      <CardCreateAction key="chargeAll" userId={userId} />
    </TopToolbar>
  );
};

export const CardsList = () => {
  const userId = useQuery().get('userId');

  if (!userId) {
    return <Loading />;
  }

  const LinkToTransactions = () => {
    const record = useRecordContext();
    const { id } = record;
    return <ListButton icon={<ImageEye />} to={`/transactions/${id}`} label={'Transactions'} />;
  };

  const addressRenderer = (record: CardModel) => {
    const address = record.provider.address;
    return [address.addressLine1, address.addressLine2, address.addressLine3, address.city, address.postCode]
      .filter(Boolean)
      .join(', ');
  };

  const printStatusRenderer = (record: CardModel) => {
    if (record.printStatus == null) {
      return 'printed';
    }
    switch (record.printStatus) {
      case CardPrintStatus.Scheduled:
        return `scheduled for ${dayjs(record.printDate).format('YYYY-MM-DD')}`;
      case CardPrintStatus.Printed:
        return 'printed';
      case CardPrintStatus.Failed:
        return 'failed';
      case CardPrintStatus.Cancelled:
        return 'cancelled';
      default:
        return 'Not printed';
    }
  };

  const { CardListExport } = useResourceAccess();

  return (
    <List
      resource={`admin/user/${userId}/cards`}
      title="Cards"
      exporter={CardListExport() ? undefined : false}
      actions={<CardsListActions />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="status" />
        <FunctionField source="printStatus" render={printStatusRenderer} />
        <FunctionField source="provider.address" render={addressRenderer} label="Address" />
        <DateField source="dateCreate" />
        <LinkToTransactions />
        <CardPinRetriesResetAction />
        <CancelCardAction />
      </Datagrid>
    </List>
  );
};
