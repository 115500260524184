import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

const UserLink = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  const userShowPage = `/users/${value}/show`;
  return <Link to={userShowPage}>{value}</Link>;
};

export default UserLink;
