import {
  Toolbar,
  Edit,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useGetRecordId,
  SaveButton,
  useCreatePath,
} from 'react-admin';

const UserBalanceAdjustToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

export const UserBalanceAdjust = () => {
  const id = useGetRecordId();
  const createPath = useCreatePath();

  return (
    <Edit resource="balance" redirect={createPath({ resource: 'users', type: 'show', id })}>
      <SimpleForm toolbar={<UserBalanceAdjustToolbar />}>
        <NumberInput source="amount" validate={required()} />
        <TextInput source="reason" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
