import {
  ChipField,
  Datagrid,
  DateField,
  EmailField,
  List,
  ShowButton,
  TextField,
  Pagination,
  TextInput,
} from 'react-admin';
import UserLink from '../UserLink';

const filters = [
  <TextInput source="userId" alwaysOn name="userId" label="User ID" />,
  <TextInput source="email" alwaysOn name="email" label="Email" />,
  <TextInput source="phoneNumber" alwaysOn name="phoneNumber" label="Phone Number" />,
];

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} perPage={50} defaultValue={50} />;

export const CreditDecisionAppList = () => {
  return (
    <List
      resource="creditdecisionapplications"
      title="Credit Decision Applications"
      actions={false}
      queryOptions={{
        refetchInterval: false,
        meta: {},
      }}
      pagination={<PostPagination />}
      filters={filters}
      perPage={50}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="dateCreated" label="Date Applied" showTime={false} sortable={false} />
        <UserLink source="speedboatUserId" label="UserId" sortable={false} />
        <TextField source="firstName" label="FirstName" sortable={false} />
        <TextField source="lastName" label="LastName" sortable={false} />
        <EmailField source="email" label="Email" sortable={false} />
        <ChipField source="status" label="Status" sortable={false} />
        <TextField source="step" label="Step" sortable={false} />
        <DateField source="dateUpdated" label="Date Modified" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
