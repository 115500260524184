import { Datagrid, DateField, DateInput, FunctionField, List, NumberField, TextField } from 'react-admin';

const filters = [<DateInput source="dateFrom" />, <DateInput source="dateTo" />];

export const StatsList = () => {
  const pausedCardBalancePercentRender = (record: any) => {
    if (
      record.cardBalanceOfCollectionsPaused == null ||
      record.totalBalanceOutstanding == null ||
      record.totalBalanceOutstanding === 0
    ) {
      return '';
    }
    return `${((record.cardBalanceOfCollectionsPaused / record.totalBalanceOutstanding) * 100).toFixed(2)}%`;
  };

  return (
    <List resource="stats" filters={filters}>
      <Datagrid>
        <DateField showTime={false} source="id" label="Date" sortable={false} />
        <TextField
          source="numberOfActiveCards"
          label={<span title="All issued cards (exclude closed)">Active cards</span>}
          sortable={false}
        />
        <TextField
          source="numberOfAwaitingActivationCards"
          label={<span title="Number of cards that require activation">Awaiting Activation cards</span>}
          sortable={false}
        />
        <TextField
          source="numberOfCloseCardRequestsToday"
          label={
            <span title="Number of all successful CloseCard requests during the day">Cards closed (this day)</span>
          }
          sortable={false}
        />
        <TextField
          source="numberOfCreateCardRequestsToday"
          label={
            <span title="Number of all successful CreateCard requests during the day">Cards issued (this day)</span>
          }
          sortable={false}
        />
        <NumberField
          source="totalOfCreditLimitApproved"
          label={
            <span title="Sum of all approved credit limits. Include only active users.">Total active credit limit</span>
          }
          sortable={false}
        />
        <NumberField
          source="totalBalanceOutstanding"
          label="Total outstanding balance"
          sortable={false}
          options={{ maximumFractionDigits: 0 }}
        />
        <NumberField
          source="utilization"
          label="Utilization"
          sortable={false}
          options={{ maximumFractionDigits: 0, style: 'percent' }}
        />
        <NumberField source="mrr" label="MRR" sortable={false} options={{ maximumFractionDigits: 0 }} />
        <NumberField source="cancelledAndDoneCount" label="Cancelled with credit cards Issued" sortable={false} />
        <NumberField source="cancelledAndWaitingCount" label="Cancelled without credit cards Issued" sortable={false} />
        <NumberField
          source="numberOfActiveUsersWithCardZeroBalance"
          label="Number of cards with non-0 balance"
          sortable={false}
        />
        <NumberField
          source="numberOfActiveUsersWithCard20PercentBalance"
          label="Number of cards with balance >20%"
          sortable={false}
        />
        <NumberField
          source="numberOfCollectionsPaused"
          label="Number of people for whom collection paused"
          sortable={false}
        />
        <NumberField
          source="cardBalanceOfCollectionsPaused"
          label="Total outstanding card balance for paused"
          sortable={false}
        />
        <NumberField
          source="subscriptionBalanceOfCollectionsPaused"
          label="Total outstanding subscription balance for paused"
          sortable={false}
        />
        <FunctionField
          source="subscriptionBalanceOfCollectionsPaused"
          label="Paused credit card balance as % of total "
          sortable={false}
          render={pausedCardBalancePercentRender}
          textAlign={'right'}
        />
        <NumberField source="avgPauseDuration" label="Average duration of pausing" sortable={false} />
      </Datagrid>
    </List>
  );
};
