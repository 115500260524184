export enum UserStatus {
  new = 'new',
  pending = 'pending',
  active = 'active',
  rejected = 'rejected',
  cancelled = 'cancelled',
  disabled = 'disabled',
}

export interface UserListResponse {
  id: string;
  userId: string;
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  status: UserStatus;
  isAdmin?: boolean;
  bitsId?: string;
}

export interface AdjustBalanceRequest {
  amount: number;
  reason: string;
}

export interface CursorResponse {
  LastEvaluatedKey: any;
}

export enum HistoryEventType {
  Stripe = 'Stripe',
  System = 'System',
  Device = 'Device',
  CardProvider = 'CardProvider',
  Subscription = 'Subscription',
  KYC = 'KYC',
  CardStatement = 'CardStatement',
  Thredd = 'Thredd',
  CreateCard = 'CreateCard',
  NoCardRequiredDone = 'NoCardRequiredDone',
  CloseCard = 'CloseCard',
  AdminManualCharge = 'AdminManualCharge',
  AdminManualChargeFailed = 'AdminManualChargeFailed',
  AdminSync3DSPhone = 'AdminSync3DSPhone',
  AdminUserUpdate = 'AdminUserUpdate',
  AdminAdjustBalance = 'AdminAdjustBalance',
  AdminTransactionUpdate = 'AdminTransactionUpdate',
  AdminSubscriptionDeactivate = 'AdminSubscriptionDeactivate',
  AdminSubscriptionPlanCreate = 'AdminSubscriptionPlanCreate',
  AdminSettingsUpdate = 'AdminSettingsUpdate',
  UserCharge = 'UserCharge',
  UserChargeFailed = 'UserChargeFailed',
  ChargeOnPaymentDate = 'ChargeOnPaymentDate',
  ChargeOnPaymentDateFailed = 'ChargeOnPaymentDateFailed',
  PreAuthSucceeded = 'PreAuthSucceeded',
  PreAuthFailed = 'PreAuthFailed',
  CreditLimitIncreased = 'CreditLimitIncreased',
  CreditLimitDecreased = 'CreditLimitDecreased',
  DelinquencyActivated = 'DelinquencyActivated',
  DelinquencyCleared = 'DelinquencyCleared',
  DelinquencyUpdated = 'DelinquencyUpdated',
  DelinquencyDefaulted = 'DelinquencyDefaulted',
  CardChangeLockSettings = 'CardChangeLockSettings',
  UserLocked = 'UserLocked',
  UserUnlocked = 'UserUnlocked',
  ViewPin = 'ViewPin',
  ViewPinFailed = 'ViewPinFailed',
  ChangePin = 'ChangePin',
  ChangePinFailed = 'ChangePinFailed',
}

export enum CardPrintStatus {
  Scheduled = 0,
  Printed = 1,
  Cancelled = 2,
  Failed = 3,
}

export enum CardType {
  'virtual' = 'virtual',
  'physical' = 'physical',
}

export enum CardStatus {
  new = 'new',
  awaitingActivation = 'awaitingActivation',
  activated = 'activated',
  failedToActivate = 'failedToActivate',
  suspended = 'suspended',
  failedToSuspend = 'failedToSuspend',
  failedToReplace = 'failedToReplace',
  failedToClose = 'failedToClose',
  failed = 'failed',
  closed = 'closed',
}

export interface LockItem {
  value: string;
  description?: string;
}

export interface CardholderProps {
  email?: string;
  telephone?: string;
}

export enum CardStatusCode {
  'Active' = '00',
  'Inactive' = '02',
  'CaptureCard' = '04',
  'DoNotHonour' = '05',
  'LostCard' = '41',
  'StolenCard' = '43',
  'ClosedAccount' = '46',
  'ExpiredCard' = '54',
  'TransactionNotPermitted' = '57',
  'SuspectedFraud' = '59',
  'RestrictedCard' = '62',
  'SecurityViolation' = '63',
  'CardholderToContactIssuer' = '70',
  'CardDestroyed' = '83',
  'RefundGivenToCustomer' = '98',
  'CardVoided' = '99',
  'ShortTermBlock' = 'G1',
  'ShortTermBlockFullBlock' = 'G2',
  'LongTermBlock' = 'G3',
  'LongTermFullBlock' = 'G4',
  'ProtectShortTermBlock' = 'G5',
  'ProtectShortTermFullBlock' = 'G6',
  'ProtectLongTermBlock' = 'G7',
  'ProtectLongTermFullBlock' = 'G8',
  'ProtectStolen' = 'G9',
}

export interface CardBalance {
  availableBalance: number;
  pendingAmount: number;
  currencyCode: string;
  cardBalance: number;
}

export interface CardHolder {
  title?: string;
  firstName?: string;
  lastName: string;
  middleName?: string;
  dateOfBirth: string;
  mobile?: string;
  email?: string;
}

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  state?: string;
  county?: string;
  postCode: string;
  country: string; // GBR
}
export interface ThreddCardProps {
  cardType: CardType;
  publicToken: string;
  status: string;
  cardStatusCode: CardStatusCode;
  cardStatusDescription: string;
  balance: CardBalance;
  cardDetails: {
    customerReference: string;
    fullNameOnCard: string;
    maskedPan: string;
    startDate: string;
    expiryDate: string;
  };
  maskedPan?: string;
  cardHolder: CardHolder;
  cardProduct:
    | {
        scheme: string;
        product: number;
        productShortName: string;
      }
    | number;
  controlGroups: {
    limitsGroup: number;
    usageGroup: number;
    recurringFeeGroup: number;
    webServiceFeeGroup: number;
    authFeeGroup: number;
    mccGroup: number;
    cardLinkageGroup: number;
    calendarGroup: number;
    fxGroup: number;
    paymentTokenUsageGroup: number;
  };
  '3DS'?: [
    {
      configuration: string;
      defaultCredentials: {
        type: string;
        value: string;
        id: number;
      }[];
      fallbackCredentials: {
        type: string;
        value: string;
        id: number;
      };
    }
  ];
  designId: string;
  parentCard: number | string;
  childCards: number[];
  siblingCards: number[];
  address: Address;
  fulfilment: Address;
  freetext1: string;
  freetext2: string;
}
export interface CardModel {
  id: string;
  cardId: string;
  userId: string;
  ledgerId: string;
  dateCreate: string;
  dateUpdate?: string;
  provider: ThreddCardProps;
  status: CardStatus;
  previousStatus?: CardStatus;
  cardholder?: CardholderProps;
  isLockEnabled?: boolean; // deprecated, use isUtilizationLockEnabled
  lockThreshold?: number;
  type: CardType;
  lockRuleId?: string | null;
  isSuspendedDueToLock?: boolean;
  maxTransactionAmount?: number;
  mccBlacklist?: LockItem[];
  mccBlacklistRuleId?: string | null;
  merchantBlacklist?: LockItem[];
  merchantBlacklistRuleId?: string | null;
  isUtilizationLockEnabled?: boolean;
  transactionLimit?: number | null; // calculated using utilization
  isMaxTransactionAmountLockEnabled?: boolean;
  maxTransactionAmountLockRuleId?: string | null;
  isNotActivated?: boolean;
  printStatus?: CardPrintStatus;
  printDate?: string;
}

export interface DelinquencyRecord {
  date: string;
  minPayment: number;
  unpaidAmount: number;
}

export enum UserKycStatus {
  kycSubmitted = 'kyc-submitted',
  kycApproved = 'kyc-approved',
  kycDeclined = 'kyc-declined',
  kycMissingData = 'kyc-missing-data',
}

export enum UserInitialKycStatus {
  initialized = 'initialized',
  created = 'created',
  pending = 'pending',
  retryRequired = 'retry-required',
  approved = 'approved',
  denied = 'denied',
}

export interface UserModel {
  userId: string;
  status: UserStatus;
  initialKycStatus?: UserInitialKycStatus;
  kycStatus?: UserKycStatus;
  lastAMLCheck?: string;
  lastFullKYCCheck?: string;
  password?: string;
  isAdmin?: boolean;
  isOtpUsed?: boolean;
  otpExpiry?: string;
  lastLogin?: string;
  dateCreate?: string;
  dateUpdate?: string;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;

  smsCode?: string | null;
  smsCodeUsed?: boolean;
  smsCodeExpiry?: string | null;

  emailCode?: string | null;
  emailCodeUsed?: boolean;
  emailCodeExpiry?: string | null;

  bitsId: string;

  netIncome?: number;
  netExpenses?: number;
  isPhysicalCardRequired?: boolean;
  isCardRequired?: boolean;
  searchFirstName?: string;
  searchLastName?: string;
  searchEmail?: string;

  stripeCustomerId?: string;
  externalUserId: string;

  creditLimit?: number;
  subscriptionPlanId?: string;
  lastUtilization?: number;

  subscriptionStartDate?: string;
  nextPaymentDate?: string;
  nextBillingDate?: string;
  nextPaymentDueDate: string;

  lastPaymentDate?: string | null;
  lastBillingDate?: string | null;
  lastPaymentDueDate?: string;

  firstPaymentDueDate?: string;

  isDelinquent?: boolean;
  delinquencyRecords?: DelinquencyRecord[];
  delinquencyDate?: string | null;

  balance?: number | null;
  spendBalance?: number | null;
  totalFee?: number | null;
  minimalPayment?: number | null;

  ledgerBalance?: number | null; // last ledger balance

  payMinimal?: boolean;

  billingDate: number;
  paymentDate: number;

  employmentStatus?: string;
  numberOfDependantPeople?: number;
  isCashAdvanceNeeded?: boolean;

  signupStep?: string;

  isDefault?: boolean;

  lastLocationIpAddress?: string;
  lastLocationCountry?: string;
  lastTokenHash?: string;

  lastStripeInvoiceId?: string | null;

  totalPayoff?: number;

  isFrozen?: boolean;
  isLocked?: boolean;
  isAutoPaymentOn?: boolean;

  // internal lock
  lockExpiresAt?: string | null;

  disabledReporting?: boolean;

  index: number;
  serial: number;

  isFirstPreAuthSucceeded?: boolean;
  isPostDelinquencyPreAuthSucceeded?: boolean | null;
  isCardBannerHidden?: boolean;
  travellingAbroadNotificationDate?: string;

  hasKba?: boolean;

  collectionsPausedUntil?: string | null;
  collectionsPausedStartedAt?: string | null;

  weeklyPaymentDay?: number;
}

export type Comment = {
  id: string;
  userId: string;
  userName: string;
  text: string;
  timestamp: string;
  isDraft: boolean;
};

export {};
