import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import UserHandler from '../../react-admin/providers/handlers/UserHandler';

const useUnlockAccount = ({ setShow, userId }: { setShow: (flag: boolean) => void; userId: string | undefined }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setReason('');
    setLoading(false);
    setShow(false);
  };
  const unlock = async () => {
    setLoading(true);
    if (userId != null) {
      UserHandler.unlock(
        userId,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => setReason(event.target.value),
    unlock,
    close,
    loading,
  };
};

export const UnlockUserAction = ({ user }: { user: any }) => {
  const [show, setShow] = useState(false);
  const { unlock, close, loading, reason, setReason } = useUnlockAccount({
    setShow,
    userId: user.id,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Unlock
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Unlock</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <span>
              <b>Attention!</b>
              <br />
              <br />
              You are about to unlock the customer's card.
              <br />
              <br />
              Are you sure you want to proceed?
            </span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="reason"
              placeholder="Reason"
              variant="outlined"
              fullWidth
              value={reason}
              onChange={setReason}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button onClick={unlock} disabled={reason === '' || loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
