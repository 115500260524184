import { ListButton, useCreatePath, useRecordContext } from 'react-admin';
import React from 'react';

const LinkToCards = () => {
  const record = useRecordContext();
  const { id } = record;
  const createPath = useCreatePath();
  const basePath = createPath({ resource: 'cards', type: 'list' });

  return <ListButton to={basePath + `?userId=${id}`} label={'Cards'} />;
};

export default LinkToCards;
