import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import CancelIcon from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { CardModel, CardStatus } from '../../utils/models';
import CardsHandler from '../../react-admin/providers/handlers/CardsHandler';

const useCancelCard = ({
  setShow,
  userId,
  cardId,
}: {
  setShow: (flag: boolean) => void;
  userId: string;
  cardId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setReason('');
    setLoading(false);
    setShow(false);
  };
  const cancel = async () => {
    setLoading(true);
    if (userId != null) {
      CardsHandler.cancel(
        cardId,
        userId,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => setReason(event.target.value),
    cancel,
    close,
    loading,
  };
};

export const CancelCardAction = () => {
  const record = useRecordContext<CardModel>();

  const [show, setShow] = useState(false);
  const { cancel, close, loading, reason, setReason } = useCancelCard({
    setShow,
    cardId: record.id,
    userId: record.userId,
  });
  return (
    <>
      {record.status !== CardStatus.closed && (
        <>
          <Button color="primary" onClick={() => setShow(true)}>
            <CancelIcon />
            Cancel
          </Button>
          <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
            <DialogTitle>Cancel card</DialogTitle>
            <DialogContent>
              <Grid item xs={12}>
                <span>
                  <b>Attention!</b>
                  <br />
                  <br />
                  You are about to cancel customer's card {record.id}.
                  <br />
                  <br />
                  Are you sure you want to proceed?
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="reason"
                  placeholder="Reason"
                  variant="outlined"
                  fullWidth
                  value={reason}
                  onChange={setReason}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={close} disabled={loading}>
                <IconCancel />
                <span>No</span>
              </Button>
              <Button onClick={cancel} disabled={reason === '' || loading}>
                <IconSave />
                <span>Yes</span>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
