import { Create, FileField, FileInput, required, SimpleForm } from 'react-admin';

export const MccUpload = () => {
  return (
    <Create>
      <SimpleForm>
        <FileInput source="file" label="Upload file (.csv)" accept=".csv" validate={required()} multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
