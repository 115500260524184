import { CreateParams, CreateResult, GetListParams, GetListResult, RaRecord } from 'react-admin';
import { getJson, postForm } from '../../../utils/api';
import IHandler from './IHandler';

export default class MccHandler implements IHandler {
  static resourceIdName = 'mcc';

  static prevNextToken: Record<number, string> = {};

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
    };

    const page = params?.pagination.page ?? 1;

    if (page > 1 && MccHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = MccHandler.prevNextToken[page - 1];
    }

    if (params?.filter?.search != null && params?.filter?.search !== '') {
      query['search'] = params?.filter?.search;
    }

    return getJson(`/admin/${resource}?${new URLSearchParams(query).toString()}`).then(async (response) => {
      let { data: responseData } = await response.json();

      const nextToken = responseData.nextToken;

      responseData = responseData?.codes || [];

      responseData = responseData.map((data: any) => {
        const result = {
          ...data,
        };

        result.id = data[this.resourceIdName];
        delete result[this.resourceIdName];

        return {
          ...result,
        };
      });

      MccHandler.prevNextToken[page] = nextToken;

      return {
        data: responseData,
        pageInfo: {
          hasNextPage: !!nextToken,
        },
      };
    });
  }

  static async createHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params?.data || {};
    const formData = new FormData();

    for (const name in payload) {
      if (payload[name].rawFile) {
        formData.append(name, payload[name].rawFile, payload[name].rawFile.name);
      } else {
        formData.append(name, payload[name]);
      }
    }

    return postForm(`/admin/${resource}`, formData).then(async () => {
      return {
        data: {
          id: '',
        },
      } as any;
    });
  }
}
