import { useCallback } from 'react';
import { Datagrid, DateField, FunctionField, List, Loading, NumberField, RaRecord, TextField } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useResourceAccess } from '../../hooks/useAccess';

export const TransactionsList = () => {
  const { cardId } = useParams();

  if (!cardId) {
    return <Loading />;
  }

  const editTransaction = useCallback((record: RaRecord) => {
    const { userId, id } = record;
    return `/transaction/${id}?userId=${userId}`;
  }, []);

  const declinedByRenderer = useCallback(
    (tr: any) =>
      tr.status === 'transaction-status-card-reservation-declined'
        ? tr.provider.Txn_Stat_Code === 'I' || tr.provider?.Note != null
          ? 'Thredd'
          : 'Bits'
        : '',
    []
  );

  const declineNoteRenderer = useCallback(
    (tr: any) =>
      tr.status === 'transaction-status-card-reservation-declined'
        ? tr.provider.Txn_Stat_Code === 'I' || tr.provider?.Note != null
          ? tr.provider.Note
          : tr.provider.lockNote ?? tr.provider.declineNote ?? ''
        : '',
    []
  );

  const additionalNotesRenderer = useCallback((tr: any) => {
    if (tr.status !== 'transaction-status-card-reservation-declined') {
      return '';
    }
    if (tr.provider.lockNote?.includes('category lock')) {
      return 'customer has blocked this mcc / merchant category in app';
    }
    if (tr.provider.lockNote?.includes('merchant lock')) {
      return 'customer has blocked merchant in app';
    }
    if (tr.provider.lockNote?.includes('maximum transaction amount lock')) {
      return 'customer has credit builder mode on';
    }
    if (tr.provider.declineNote === `MCC disabled globally`) {
      return 'bits has blocked this mcc / merchant category for everyone';
    }
    if (tr.provider.declineNote === `merchant disabled globally`) {
      return 'bits has blocked this merchant for everyone';
    }
    if (tr.provider.declineNote === `declined due to card status awaitingActivation`) {
      return 'customer has not activated card';
    }
    if (tr.provider.declineNote === `declined due to card status closed`) {
      return 'the card is cancelled';
    }
    if (tr.provider.declineNote === `declined due to card status suspended`) {
      return 'customer card is locked because of overdue balance';
    }
    if (tr.provider.Note?.includes('Fraud Advantage')) {
      return 'declined by Thredd fraud detection';
    }

    return '';
  }, []);

  const { TransactionListExport } = useResourceAccess();
  return (
    <List
      resource={`admin/card/${cardId}/transactions`}
      title="Transactions"
      exporter={TransactionListExport() ? undefined : false}
    >
      <Datagrid rowClick={(id, resource, record) => editTransaction(record)}>
        <TextField source="id" />
        <TextField source="provider.card_transaction_type" label={'Type'} />
        <TextField source="provider.merchant_details" label={'Merchant'} />
        <NumberField source="amount" />
        <TextField source="provider.MCC_Desc" label={'MCC Description'} />
        <FunctionField render={declinedByRenderer} label={'Declined by'} />
        <FunctionField render={declineNoteRenderer} label={'Decline note'} />
        <FunctionField render={additionalNotesRenderer} label={'Additional notes'} />
        <TextField source="status" />
        <TextField source="type" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
