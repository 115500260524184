import { GetListParams, GetListResult, GetManyReferenceParams, GetManyReferenceResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class UserTransactionsHandler implements IHandler {
  static resourceIdName = 'transactionId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
    };

    if (params?.filter.nextToken != null) {
      query.nextToken = params?.filter.nextToken;
    }

    const userId = params?.filter?.userId;

    return getJson(`/admin/user/${userId}/transactions?${new URLSearchParams(query).toString()}`).then(
      async (response) => {
        let { data: responseData } = await response.json();

        const nextToken = responseData.nextToken;

        responseData = responseData?.transactions || [];

        responseData = responseData.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];

          return {
            ...result,
          };
        });

        return {
          data: responseData,
          pageInfo: {
            hasNextPage: !!nextToken,
          },
          nextToken,
        };
      }
    );
  }

  static async getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    const data: any[] = [];
    let nextToken = null;
    do {
      const ret: any = await UserTransactionsHandler.getListHandler(resource, {
        filter: {
          userId: params?.id,
          ...params.filter,
          nextToken,
        },
        meta: params.meta,
        pagination: params.pagination,
        sort: params.sort,
      });
      console.log(ret);
      nextToken = ret.nextToken;
      data.push(...ret.data);
    } while (nextToken != null);

    return {
      data,
      pageInfo: {
        hasNextPage: false,
      },
    };
  }
}
