import React from 'react';
import { Card, CardContent } from '@mui/material';
import { BooleanInput, Edit, NumberInput, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const CreditDecisionConfig: React.FC = () => {
  const { SettingsDetailsEdit } = useResourceAccess();
  const isRo = !SettingsDetailsEdit();

  return (
    <Card>
      <CardContent>
        <Edit
          resource="creditdecisionconfig"
          id={'default'}
          transform={(data: any) => {
            return {
              defaultCreditLimit: data.defaultCreditLimit,
              showBannerToAllNewUsers: data.showBannerToAllNewUsers,
              omitPhoneNumberCheck: data.omitPhoneNumberCheck,
              useSubscriptionPlanCreditLimit: data.useSubscriptionPlanCreditLimit,
            };
          }}
          actions={false}
          redirect={false}
          title=" (Credit Decision)"
          mutationMode={'pessimistic'}
        >
          <SimpleForm toolbar={!isRo ? <CustomToolbar /> : false}>
            <NumberInput source="defaultCreditLimit" min={50} max={4000} disabled={isRo} />
            <BooleanInput source="showBannerToAllNewUsers" disabled={isRo}></BooleanInput>
            <BooleanInput source="omitPhoneNumberCheck" disabled={isRo}></BooleanInput>
            <BooleanInput source="useSubscriptionPlanCreditLimit" disabled={isRo}></BooleanInput>
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default CreditDecisionConfig;
