import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import UserHandler from '../../react-admin/providers/handlers/UserHandler';

const usePauseCollections = ({
  setShow,
  userId,
  balance,
}: {
  setShow: (flag: boolean) => void;
  balance: number;
  userId: string | undefined;
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [amount, setAmount] = useState(balance);
  const [blockSignup, setBlockSignup] = useState(false);

  const notify = useNotify();

  const refresh = useRefresh();

  const close = () => {
    setReason('');
    setLoading(false);
    setShow(false);
  };

  const waive = async () => {
    setLoading(true);
    if (userId != null) {
      UserHandler.waiveBalance(
        userId,
        amount,
        blockSignup,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => {
      setReason(event?.target?.value ?? '');
    },
    waive,
    close,
    loading,
    amount,
    blockSignup,
    setAmount: (event: any) => {
      setAmount(parseFloat(event?.target?.value ?? ''));
    },
    setBlockSignup: (event: any) => {
      console.log(event);
      setBlockSignup(event?.target?.checked ?? false);
    },
  };
};

export const WaiveBalanceAction = ({ user, amount: balance }: { user: any; amount: number }) => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { waive, close, loading, reason, setReason, amount, setAmount, blockSignup, setBlockSignup } =
    usePauseCollections({
      setShow,
      balance,
      userId: user.id,
    });
  const setReasonSelect = (event: any) => {
    if (event.target.value !== 'other') {
      setReason(event);
    } else {
      setReason(null);
    }
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setSelectedOption('');
    setReason('');
  }, [show]);

  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Waive balance
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Waive balance</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <span>
              <b>Attention!</b>
              <br />
              <br />
              You are about to waive customer's balance.
              <br />
              <br />
              {blockSignup && (
                <>
                  <strong>WARNING:</strong> The customers account will be cancelled and signup will be blocked.
                  <br />
                </>
              )}
              Are you sure you want to proceed?
            </span>
          </Grid>
          <Grid item xs={12}>
            {selectedOption !== 'other' && (
              <Select
                native
                variant="outlined"
                fullWidth
                id="reasonSelect"
                onChange={setReasonSelect}
                placeholder={'Select reason'}
                sx={{ marginTop: 1 }}
                value={reason}
              >
                <option></option>
                <option>customer vulnerability or situation</option>
                <option>bankruptcy or debt relief order</option>
                <option>fraud on the card or account</option>
                <option>other</option>
              </Select>
            )}
            {selectedOption === 'other' && (
              <TextField
                id="reason"
                placeholder="Reason"
                variant="outlined"
                fullWidth
                value={reason}
                onChange={setReason}
              />
            )}
            <TextField
              id="amount"
              value={amount}
              label={'Amount to waive'}
              fullWidth
              type={'number'}
              onChange={setAmount}
            ></TextField>
            <Checkbox
              checked={blockSignup}
              title={'Cancel account and block signup'}
              onChange={setBlockSignup}
            ></Checkbox>
            Cancel account and block signup
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button onClick={waive} disabled={reason === '' || isNaN(amount) || loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
