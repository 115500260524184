import { GetListResult, RaRecord } from 'react-admin';
import { getJson, postJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class CardsHandler implements IHandler {
  static resourceIdName = 'cardId';
  static route = '/admin/user';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string
  ): Promise<GetListResult<RecordType>> {
    return getJson(`/${resource}`)
      .then(async (response) => {
        let { data: responseData } = await response.json();

        responseData = responseData.Items ?? responseData;
        responseData = responseData?.length > 0 ? responseData : [];

        responseData = responseData.map((data: any) => {
          const result = {
            ...data,
          };

          result.id = data[this.resourceIdName];
          delete result[this.resourceIdName];

          return {
            ...result,
          };
        });

        return {
          data: responseData,
          total: responseData.length,
        };
      })
      .catch((response) => {
        if (response.status === 400) {
          return {
            data: [],
            total: 0,
          };
        }
        return Promise.reject();
      });
  }
  static async cancel(
    cardId: string,
    userId: string,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return putJson(`${this.route}/${userId}/cards/${cardId}/deactivate`, { reason })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async unblockPin(
    cardId: string,
    userId: string,
    reason: string,
    onSuccess: () => void,
    onFailure: (err: string) => void
  ) {
    return putJson(`${this.route}/${userId}/cards/${cardId}/pin-reset`, { reason })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }

  static async createCard(userId: string, reason: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return postJson(`${this.route}/${userId}/cards/`, { reason, type: 'physical' })
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
