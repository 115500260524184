import { createDateRenderer } from '../../utils/misc';

import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  SelectArrayInput,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  useRecordContext,
  SelectInput,
} from 'react-admin';
import { HistoryEventType } from '../../utils/models';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AdminConfigHandler from '../../react-admin/providers/handlers/AdminConfigHandler';
import UserManagementHandler from '../../react-admin/providers/handlers/UserManagementHandler';

const PostPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={25} defaultValue={25} />;

const UserNameLinkField = (props: any) => {
  const record = useRecordContext(props);
  const { firstName, lastName, email, membershipNumber } = record;

  const userShowPage = `/users/${membershipNumber}/show`;
  return (
    <Link to={userShowPage}>
      <span title={email}>
        {firstName} {lastName}
      </span>
    </Link>
  );
};

export const HistoryList = () => {
  const [staff, setStaff] = useState<any[]>([]);

  const getFilters = (eventTypes: any) => {
    return [
      <SelectArrayInput
        source="eventTypes"
        choices={Object.values(eventTypes).map((value) => ({ id: value, name: value }))}
        alwaysOn
        sx={{ width: 300 }}
      />,
      <ReferenceInput source="userId" reference="usermanagement" alwaysOn filter={{ searchOnly: true }}>
        <AutocompleteInput
          label="Customer"
          filterToQuery={(t: string) => ({ email: t })}
          optionText={'email'}
          sx={{ width: 300 }}
        />
      </ReferenceInput>,
      <SelectInput
        resettable
        label="Admin"
        source="initiator"
        choices={staff.map((user) => ({ id: user.userId, name: `${user.firstName} ${user.lastName}` }))}
        emptyText={'Any'}
        alwaysOn
        sx={{ width: 300 }}
      />,
      <DateInput source="from" label="After" alwaysOn />,
      <DateInput source="to" label="Before" alwaysOn />,
    ];
  };

  useEffect(() => {
    AdminConfigHandler.getOneHandler().then((res) => {
      UserManagementHandler.getMany('', { ids: res.data.staff }).then((res) => {
        setStaff(res.data);
      });
    });
  }, []);

  return (
    <List
      resource={'history'}
      actions={false}
      filters={getFilters(HistoryEventType)}
      pagination={<PostPagination />}
      queryOptions={{
        refetchInterval: false,
        meta: {},
      }}
      perPage={25}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField source="timestamp" render={createDateRenderer('timestamp')} label={'Created'} />
        <TextField source="eventMessage" />
        <ReferenceField reference={'usermanagement'} source={'userId'} label={'User'}>
          <UserNameLinkField />
        </ReferenceField>
        <ReferenceField reference={'usermanagement'} source={'initiator'} label={'ChangedBy'}>
          <UserNameLinkField />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default HistoryList;
