import { Card, CardContent, Grid } from '@mui/material';
import { DateInput, Edit, Form, Loading, NumberInput, TextInput, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

export const TransactionsEdit = () => {
  const { id } = useParams();
  const userId = useQuery().get('userId');

  if (!userId || !id) {
    return <Loading />;
  }

  const ProviderView = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <pre>{JSON.stringify(record.provider, null, '\t')}</pre>;
  };

  return (
    <Card>
      <CardContent>
        <Edit
          resource={`admin/user/${userId}/transactions`}
          id={id}
          redirect={`/transactions/${id}?userId=${userId}`}
          title="Edit Transaction"
        >
          <Form>
            <Grid container>
              <Grid item xs={3}>
                <TextInput source="status" />
              </Grid>
              <Grid item xs={3}>
                <TextInput source="type" />
              </Grid>
              <Grid item xs={3}>
                <NumberInput source="amount" />
              </Grid>
              <Grid item xs={3}>
                <DateInput source="createdAt" />
              </Grid>
            </Grid>
            <ProviderView />
          </Form>
        </Edit>
      </CardContent>
    </Card>
  );
};
