import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class TransactionsHandler implements IHandler {
  static resourceIdName = 'transactionId';

  static prevNextToken: Record<number, string> = {};

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      limit: params?.pagination.perPage ?? 10,
    };

    const page = params?.pagination.page ?? 1;

    if (page > 1 && TransactionsHandler.prevNextToken[page - 1] != null) {
      query['nextToken'] = TransactionsHandler.prevNextToken[page - 1];
    }

    return getJson(`/${resource}?${new URLSearchParams(query).toString()}`).then(async (response) => {
      let { data: responseData } = await response.json();

      const nextToken = responseData.nextToken;

      responseData = responseData?.transactions || [];

      responseData = responseData.map((data: any) => {
        const result = {
          ...data,
        };

        result.id = data[this.resourceIdName];
        delete result[this.resourceIdName];

        return {
          ...result,
        };
      });

      TransactionsHandler.prevNextToken[page] = nextToken;

      return {
        data: responseData,
        pageInfo: {
          hasNextPage: !!nextToken,
        },
      };
    });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`/${resource}/${id}`).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];

      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const id = params?.id;
    const payload = params?.data || {};
    delete payload.userId;
    delete payload.cardId;
    delete payload.id;

    return putJson(`/${resource}/${id}`, payload).then(async (response) => {
      const { data } = await response.json();

      data.id = data[this.resourceIdName];
      delete data[this.resourceIdName];

      return {
        data: {
          ...data,
        },
      };
    });
  }
}
