import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  NumberField,
  Pagination,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import { CURRENCY } from '../../utils/money';
import { CustomReferenceField } from '../CustomReferenceField';
import { UserStatus } from '../../utils/models';
import { useResourceAccess } from '../../hooks/useAccess';

const filters = [
  <TextInput label="BitsId" source="bitsId" alwaysOn />,
  <TextInput source="email" alwaysOn name="email" label="Email" />,
  <TextInput source="phoneNumber" alwaysOn name="phoneNumber" label="Phone Number" />,
  <SelectInput
    resettable
    label="Status"
    source="status"
    choices={Object.keys(UserStatus).map((status) => ({ id: status, name: status }))}
    emptyText={'Any'}
    alwaysOn
    sx={{ width: 300 }}
  />,
];

export const UsersList = () => {
  const { UserSearchExport } = useResourceAccess();

  return (
    <List
      resource="users"
      filters={filters}
      perPage={50}
      pagination={<Pagination perPage={50} rowsPerPageOptions={[10, 25, 50, 100]} />}
      exporter={UserSearchExport() ? undefined : false}
    >
      <Datagrid>
        <NumberField source="serial" sortable={false} />
        <TextField source="bitsId" label="BitsID" sortable={false} />
        <CustomReferenceField reference={'usermanagement'} source={'id'} label={'FirstName'} sortable={false}>
          <TextField source="firstName" />
        </CustomReferenceField>
        <CustomReferenceField reference={'usermanagement'} source={'id'} label={'LastName'} sortable={false}>
          <TextField source="lastName" />
        </CustomReferenceField>
        <TextField source="status" sortable={false} />
        <CustomReferenceField reference={'usermanagement'} source={'id'} label={'Email'} sortable={false}>
          <EmailField source="email" />
        </CustomReferenceField>
        <CustomReferenceField reference={'usermanagement'} source={'id'} label={'Phone'} sortable={false}>
          <EmailField source="phoneNumber" />
        </CustomReferenceField>
        <DateField
          source="subscriptionStartDate"
          label={<span title="Subscription Start Date">Start</span>}
          sortable={false}
        />
        <DateField source="nextBillingDate" label={<span title="Next Billing Date">NDB</span>} sortable={false} />
        <DateField source="nextPaymentDate" label={<span title="Next Payment Date">NPD</span>} sortable={false} />
        <NumberField
          source="creditLimit"
          label={<span title="Credit Limit">CL</span>}
          sortable={false}
          options={{ style: 'currency', currency: CURRENCY }}
        />
        <NumberField
          source="ledgerBalance"
          label={<span title="Card Balance">Available</span>}
          sortable={false}
          options={{ style: 'currency', currency: CURRENCY }}
        />
        <NumberField
          source="totalPayoff"
          label={<span title="Total amount repaid">Payoff</span>}
          sortable={false}
          options={{ style: 'currency', currency: CURRENCY }}
        />
        <NumberField
          source="lastUtilization"
          label={<span title="Utilization">UTL</span>}
          sortable={false}
          options={{ style: 'percent' }}
        />
        <CustomReferenceField reference={'balance'} source={'id'} label={'Balance'} link={false} sortable={false}>
          <NumberField source="balance" options={{ style: 'currency', currency: CURRENCY }} />
        </CustomReferenceField>
        <BooleanField source="isLocked" label={<span title="Is Locked?">Locked</span>} sortable={false} />
        <BooleanField
          source="isFrozen"
          label={<span title="Is Frozen by overdue Bits subscription?">Frozen</span>}
          sortable={false}
        />
        <BooleanField source="isDelinquent" label={<span title="Is Delinquent?">DLQ</span>} sortable={false} />
        <BooleanField source="isDefault" label={<span title="Is Default?">DEF?</span>} sortable={false} />
        <DateField source="dateCreate" label="Created" showTime={false} sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
