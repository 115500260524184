import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import Phone3DSHandler from '../../react-admin/providers/handlers/Phone3DSHandler';

const useSync3DS = ({ setShow, userId }: { setShow: (flag: boolean) => void; userId: string | undefined }) => {
  const [loading, setLoading] = useState(false);

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setShow(false);
  };
  const sync = async () => {
    setLoading(true);
    if (userId != null) {
      Phone3DSHandler.sync(
        userId,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    sync,
    close,
    loading,
  };
};

export const Sync3DSAction = ({ user }: { user: any }) => {
  const [show, setShow] = useState(false);
  const { sync, close, loading } = useSync3DS({
    setShow,
    userId: user.id,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Sync 3DS Phone Number
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Cancel</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <span>Are you sure you want to sync 3DS phone number with Bits database record?</span>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button onClick={sync} disabled={loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
