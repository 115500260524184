import { postJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class TasksHandler implements IHandler {
  static route = '/admin/tasks';
  static resourceIdName = 'userId';

  static async startTask(taskName: string, data: any, onSuccess: () => void, onFailure: (err: string) => void) {
    return postJson(`${this.route}/${taskName}`, data)
      .then(async () => {
        onSuccess();
        return {
          data: {},
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
