import { BrowserRouter, Route } from 'react-router-dom';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import Dashboard from './components/common/Dashboard';
import { Layout } from './react-admin/components/Layout';
import dataProvider from './react-admin/providers/dataProvider';
import { CognitoLogin } from './components/CognitoLogin';
import { UsersList } from './components/UsersList';
import UsersEdit from './components/UsersEdit';
import { UsersDetails } from './components/UsersDetails';
import { UsersBalanceDetails } from './components/UserBalanceDetails';
import { UserBalanceAdjust } from './components/UserBalanceAdjust';
import { StatsList } from './components/StatsList';
import { MccList } from './components/MccList';
import { MccUpload } from './components/MccUpload';
import { CardsList } from './components/CardsList';
import { TransactionsList } from './components/TransactionsList';
import { TransactionsEdit } from './components/TransactionsEdit';
import { createCognitoAuthProvider } from './react-admin/providers/cognitoAuthProvider';
import { CreditDecisionAppList } from './components/CreditDecisionAppList';
import { CreditDecisionAppDetails } from './components/CreditDecisionAppDetails';
import HistoryList from './components/HistoryList';
import RepaymentHistoryList from './components/RepaymentHistory';
import SettingsEdit from './components/SettingsEdit';

const provider = createCognitoAuthProvider();

const App = () => {
  return (
    <BrowserRouter>
      <Admin
        dashboard={Dashboard}
        layout={Layout}
        dataProvider={dataProvider}
        authProvider={provider}
        loginPage={CognitoLogin}
        disableTelemetry
      >
        {(permissions) => (
          <>
            {permissions?.UserSearchView() && (
              <Resource name="users" list={UsersList} edit={UsersEdit} show={UsersDetails} />
            )}
            {permissions?.UserDetailsCharge() && (
              <Resource name="balance" show={UsersBalanceDetails} edit={UserBalanceAdjust} />
            )}
            {permissions?.DashboardView() && <Resource name="stats" list={StatsList} />}
            {permissions?.MCCSearchView() && <Resource name="mcc" list={MccList} create={MccUpload} />}
            {permissions?.ApplicationSearchView() && (
              <Resource
                name="creditdecisionapplications"
                list={CreditDecisionAppList}
                show={CreditDecisionAppDetails}
              />
            )}

            <CustomRoutes>
              {permissions?.SettingsDetailsView() && <Route path="/settings/*" element={<SettingsEdit />} />}
              {permissions?.CardListView() && <Route path="/cards" element={<CardsList />} />}
              {permissions?.TransactionListView() && (
                <Route path="/transactions/:cardId" element={<TransactionsList />} />
              )}
              {permissions?.TransactionListView() && <Route path="/transaction/:id" element={<TransactionsEdit />} />}
              {permissions?.ActivitySearchView() && <Route path="/history/*" element={<HistoryList />} />}
              {permissions?.RepaymentSearchView() && <Route path="/repayment/*" element={<RepaymentHistoryList />} />}
            </CustomRoutes>
          </>
        )}
      </Admin>
    </BrowserRouter>
  );
};

export default App;
