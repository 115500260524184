import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import UserHandler from '../../react-admin/providers/handlers/UserHandler';

const useSetCreditLimit = ({
  setShow,
  userId,
  creditLimit,
}: {
  setShow: (flag: boolean) => void;
  userId: string | undefined;
  creditLimit: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [newCreditLimit, setNewCreditLimit] = useState<number>(creditLimit);

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setNewCreditLimit(creditLimit);
    setLoading(false);
    setShow(false);
  };
  const setCreditLimit = async () => {
    setLoading(true);
    if (userId != null) {
      UserHandler.setCreditLimit(
        userId,
        newCreditLimit,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    newCreditLimit,
    setNewCreditLimit: (event: any) => setNewCreditLimit(parseInt(event.target.value, 10)),
    setCreditLimit,
    close,
    loading,
  };
};

export const SetCreditLimitAction = ({ user }: { user: any }) => {
  const [show, setShow] = useState(false);
  const { setCreditLimit, close, loading, newCreditLimit, setNewCreditLimit } = useSetCreditLimit({
    setShow,
    userId: user.id,
    creditLimit: user.creditLimit,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Set Credit Limit
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Set Credit Limit</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <TextField
              id="reason"
              placeholder="Reason"
              variant="outlined"
              fullWidth
              value={newCreditLimit}
              onChange={setNewCreditLimit}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button
            onClick={setCreditLimit}
            disabled={newCreditLimit === user.creditLimit || isNaN(newCreditLimit) || loading}
          >
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
