export const CurrencyMapping: Readonly<Record<string, string>> = Object.freeze({
  '784': 'AED',
  '971': 'AFN',
  '8': 'ALL',
  '51': 'AMD',
  '532': 'ANG',
  '973': 'AOA',
  '32': 'ARS',
  '36': 'AUD',
  '533': 'AWG',
  '944': 'AZN',
  '977': 'BAM',
  '52': 'BBD',
  '50': 'BDT',
  '975': 'BGN',
  '48': 'BHD',
  '108': 'BIF',
  '60': 'BMD',
  '96': 'BND',
  '68': 'BOB',
  '984': 'BOV',
  '986': 'BRL',
  '44': 'BSD',
  '64': 'BTN',
  '72': 'BWP',
  '933': 'BYN',
  '974': 'BYR',
  '84': 'BZD',
  '124': 'CAD',
  '976': 'CDF',
  '947': 'CHE',
  '756': 'CHF',
  '948': 'CHW',
  '990': 'CLF',
  '152': 'CLP',
  '157': 'CNH',
  '156': 'CNY',
  '170': 'COP',
  '970': 'COU',
  '188': 'CRC',
  '931': 'CUC',
  '192': 'CUP',
  '132': 'CVE',
  '203': 'CZK',
  '262': 'DJF',
  '208': 'DKK',
  '214': 'DOP',
  '12': 'DZD',
  '818': 'EGP',
  '232': 'ERN',
  '230': 'ETB',
  '978': 'EUR',
  '242': 'FJD',
  '238': 'FKP',
  '826': 'GBP',
  '981': 'GEL',
  '936': 'GHS',
  '292': 'GIP',
  '270': 'GMD',
  '324': 'GNF',
  '320': 'GTQ',
  '328': 'GYD',
  '344': 'HKD',
  '340': 'HNL',
  '191': 'HRK',
  '332': 'HTG',
  '348': 'HUF',
  '360': 'IDR',
  '376': 'ILS',
  '356': 'INR',
  '368': 'IQD',
  '364': 'IRR',
  '352': 'ISK',
  '388': 'JMD',
  '400': 'JOD',
  '392': 'JPY',
  '404': 'KES',
  '417': 'KGS',
  '116': 'KHR',
  '174': 'KMF',
  '408': 'KPW',
  '410': 'KRW',
  '414': 'KWD',
  '136': 'KYD',
  '398': 'KZT',
  '418': 'LAK',
  '422': 'LBP',
  '144': 'LKR',
  '430': 'LRD',
  '426': 'LSL',
  '434': 'LYD',
  '504': 'MAD',
  '498': 'MDL',
  '969': 'MGA',
  '807': 'MKD',
  '104': 'MMK',
  '496': 'MNT',
  '446': 'MOP',
  '478': 'MRO',
  '929': 'MRU',
  '480': 'MUR',
  '462': 'MVR',
  '454': 'MWK',
  '484': 'MXN',
  '979': 'MXV',
  '458': 'MYR',
  '943': 'MZN',
  '516': 'NAD',
  '566': 'NGN',
  '558': 'NIO',
  '578': 'NOK',
  '524': 'NPR',
  '554': 'NZD',
  '512': 'OMR',
  '590': 'PAB',
  '604': 'PEN',
  '598': 'PGK',
  '608': 'PHP',
  '586': 'PKR',
  '985': 'PLN',
  '600': 'PYG',
  '634': 'QAR',
  '946': 'RON',
  '941': 'RSD',
  '643': 'RUB',
  '810': 'RUR',
  '646': 'RWF',
  '682': 'SAR',
  '90': 'SBD',
  '690': 'SCR',
  '938': 'SDG',
  '752': 'SEK',
  '702': 'SGD',
  '654': 'SHP',
  '925': 'SLE',
  '694': 'SLL',
  '706': 'SOS',
  '968': 'SRD',
  '728': 'SSP',
  '678': 'STD',
  '930': 'STN',
  '222': 'SVC',
  '760': 'SYP',
  '748': 'SZL',
  '764': 'THB',
  '972': 'TJS',
  '795': 'TMM',
  '934': 'TMT',
  '788': 'TND',
  '776': 'TOP',
  '792': 'TRL',
  '949': 'TRY',
  '780': 'TTD',
  '901': 'TWD',
  '834': 'TZS',
  '980': 'UAH',
  '800': 'UGX',
  '840': 'USD',
  '997': 'USN',
  '998': 'USS',
  '940': 'UYI',
  '858': 'UYU',
  '927': 'UYW',
  '860': 'UZS',
  '937': 'VEF',
  '928': 'VES',
  '704': 'VND',
  '548': 'VUV',
  '882': 'WST',
  '950': 'XAF',
  '961': 'XAG',
  '959': 'XAU',
  '955': 'XBA',
  '956': 'XBB',
  '957': 'XBC',
  '958': 'XBD',
  '951': 'XCD',
  '960': 'XDR',
  '952': 'XOF',
  '964': 'XPD',
  '953': 'XPF',
  '962': 'XPT',
  '994': 'XSU',
  '963': 'XTS',
  '965': 'XUA',
  '999': 'XXX',
  '886': 'YER',
  '710': 'ZAR',
  '894': 'ZMK',
  '967': 'ZMW',
  '716': 'ZWD',
  '932': 'ZWL',
});
