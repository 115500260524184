import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import CardsHandler from '../../react-admin/providers/handlers/CardsHandler';

const useCardCreate = ({ setShow, userId }: { setShow: (flag: boolean) => void; userId: string }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setReason('');
    setLoading(false);
    setShow(false);
  };
  const cancel = async () => {
    setLoading(true);
    if (userId != null) {
      CardsHandler.createCard(
        userId,
        reason,
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    reason,
    setReason: (event: any) => setReason(event.target.value),
    cancel,
    close,
    loading,
  };
};

export const CardCreateAction = ({ userId }: { userId: string }) => {
  const [show, setShow] = useState(false);
  const { cancel, close, loading, reason, setReason } = useCardCreate({
    setShow,
    userId,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Create Card
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Create Card</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <span>
              You are about to create card.
              <br />
              <br />
              Are you sure you want to proceed?
            </span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="reason"
              placeholder="Reason"
              variant="outlined"
              fullWidth
              value={reason}
              onChange={setReason}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button onClick={cancel} disabled={reason === '' || loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
