import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { BooleanInput, Edit, SelectInput, SimpleForm } from 'react-admin';
import { CommentsInput } from '../CommentsInput';

export const WeeklyPaymentDays = [
  { id: 0, name: 'Sunday' },
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
];

const UsersEdit = () => {
  // const { data } = useGetOne('config', {
  //   id: '',
  // });

  return (
    <Card>
      <CardContent>
        <Edit resource="users" title="User" mutationMode={'pessimistic'}>
          <SimpleForm>
            <Box display="flex" gap={2}>
              <Box display="flex" flexDirection="column" gap={2}>
                <BooleanInput source="isPhysicalCardRequired" />
                <BooleanInput source="isCardRequired" />
                <BooleanInput source="disabledReporting" />
                <SelectInput
                  label="Weekly Payment Day"
                  source="weeklyPaymentDay"
                  choices={WeeklyPaymentDays}
                  alwaysOn
                  sx={{ width: 300 }}
                />
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  height: 500,
                  width: 540,
                  overflowY: 'auto',
                  border: '1px solid #ddd',
                  padding: 2,
                }}
              >
                <CommentsInput source="comments" />
              </Box>
            </Box>
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default UsersEdit;
