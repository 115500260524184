import { ReferenceField, ReferenceFieldProps } from 'react-admin';
import { DEFAULT_QUERY_OPTIONS } from '../../utils/query-util';

export const CustomReferenceField = (props: ReferenceFieldProps) => {
  return (
    <ReferenceField
      {...props}
      queryOptions={{
        ...DEFAULT_QUERY_OPTIONS,
        ...props.queryOptions,
      }}
    />
  );
};
