import IHandler from './IHandler';
import { GetListParams, GetListResult, GetManyReferenceParams, GetManyReferenceResult, RaRecord } from 'react-admin';
import { getJson } from '../../../utils/api';

export default class HistoryHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin/history/search';
  static resourceIdName = 'requestId';

  static async getListHandler<RecordType extends RaRecord = any>(
    resource?: string,
    params?: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const query: { [key: string]: any } = {
      ...params?.filter,
      limit: params?.pagination.perPage ?? 10,
      eventTypes:
        (params?.filter?.eventTypes ?? params?.meta?.eventTypes ?? params?.meta?.defaultEventTypes ?? []).join(',') ??
        undefined,
      userId: params?.filter?.userId ?? params?.meta?.userId ?? undefined,
    };

    const page = params?.pagination.page ?? 1;
    if (page > 1) {
      query.nextToken = HistoryHandler.prevNextToken[page - 1];
    }

    for (const param in query) {
      if (query[param] === undefined || query[param] === null || query[param] === '') {
        delete query[param];
      }
    }

    return getJson(`${this.route}?${new URLSearchParams(query).toString()}`).then(async (response) => {
      let { data: responseData } = await response.json();
      const nextToken = responseData?.nextToken;
      HistoryHandler.prevNextToken[page] = JSON.stringify(nextToken);
      responseData = responseData?.events?.map((data: any) => {
        const result = {
          ...data,
        };

        result.id = data[this.resourceIdName];
        delete result[this.resourceIdName];
        return {
          ...result,
        };
      });

      return {
        data: responseData,
        pageInfo: {
          hasNextPage: !!nextToken,
          nextPageToken: nextToken ?? undefined,
        },
      };
    });
  }

  static async getManyReference<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    return HistoryHandler.getListHandler(resource, {
      filter: {
        userId: params?.id,
        ...params.filter,
      },
      meta: params.meta,
      pagination: params.pagination,
      sort: params.sort,
    });
  }
}
