import React, { useCallback } from 'react';
import { Menu as RaMenu, DashboardMenuItem, MenuItemLink, Button, useLogout } from 'react-admin';

import UserIcon from '@mui/icons-material/Group';
import SettingsIcons from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useResourceAccess } from '../../hooks/useAccess';

export const Menu: React.FC = () => {
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout();
  }, []);

  const {
    DashboardView,
    UserSearchView,
    ApplicationSearchView,
    RepaymentSearchView,
    SettingsDetailsView,
    MCCSearchView,
    ActivitySearchView,
  } = useResourceAccess();

  return (
    <RaMenu>
      {DashboardView() && <DashboardMenuItem />}
      {UserSearchView() && <MenuItemLink to="/users" primaryText="Users" leftIcon={<UserIcon />} />}
      {ApplicationSearchView() && (
        <MenuItemLink
          to="/creditdecisionapplications"
          primaryText="Applications"
          leftIcon={<FormatListBulletedIcon />}
        />
      )}
      {RepaymentSearchView() && (
        <MenuItemLink to="/repayment" primaryText="Repayment" leftIcon={<FormatListBulletedIcon />} />
      )}
      {SettingsDetailsView() && <MenuItemLink to="/settings" primaryText="Settings" leftIcon={<SettingsIcons />} />}
      {MCCSearchView() && <MenuItemLink to="/mcc" primaryText="MCC" leftIcon={<SettingsIcons />} />}
      {ActivitySearchView() && (
        <MenuItemLink to={{ pathname: '/history' }} primaryText="Activity" leftIcon={<SettingsIcons />} />
      )}
      <Button onClick={handleLogout}>
        <>Logout</>
      </Button>
    </RaMenu>
  );
};
