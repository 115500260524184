import { GetListResult, GetOneResult, RaRecord, UpdateParams, UpdateResult } from 'react-admin';
import { getJson, putJson } from '../../../utils/api';
import IHandler from './IHandler';
import * as EmailValidator from 'email-validator';

export default class WaitlistHandler implements IHandler {
  static route = '/admin/waitlist';
  static resourceIdName = 'id';

  static async getListHandler<RecordType extends RaRecord = any>(): Promise<GetListResult<RecordType>> {
    return getJson(`${this.route}`, process.env.REACT_APP_BASE_CREDIT_DECISION_API_URL).then(async (response) => {
      let { data: responseData } = await response.json();

      responseData = responseData.Items ?? responseData;
      responseData = responseData?.length > 0 ? responseData : [];

      responseData = responseData.map((data: any) => {
        const result = {
          ...data,
        };

        return {
          ...result,
        };
      });

      return {
        data: responseData,
        total: responseData.length,
      };
    });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(): Promise<GetOneResult<RecordType>> {
    return getJson(`${this.route}`, process.env.REACT_APP_BASE_CREDIT_DECISION_API_URL).then(async (response) => {
      const { data } = await response.json();

      return {
        data: {
          id: 'default',
          emails: data.items.map((item: any) => item.email).join('\n'),
        },
      } as any;
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const payload: {
      emails: string[];
    } = { emails: (params?.data?.emails ?? '').split('\n').filter((email: string) => email.trim() !== '') };
    const wrongEmails = payload.emails.filter((email) => !EmailValidator.validate(email));
    if (wrongEmails.length > 0) {
      return Promise.reject(`Invalid emails: ${wrongEmails.join(', ')}`);
    }

    return putJson(`${this.route}`, payload, process.env.REACT_APP_BASE_CREDIT_DECISION_API_URL)
      .then(async () => {
        return {
          data: {
            id: 'default',
            emails: params?.data?.emails,
          },
        } as any;
      })
      .catch((response) => {
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response?.error?.errorMessage
            ? `Sorry this person can not be added due to the following rules: ${response?.error?.errorMessage}`
            : response.statusText,
        });
      });
  }
}
