import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const WaitlistConfig: React.FC = () => {
  const { WaitlistEdit } = useResourceAccess();
  const isRo = !WaitlistEdit();

  return (
    <Card>
      <CardContent>
        <Edit resource="waitlist" id={'default'} actions={false} redirect={false} title=" (Credit Decision)">
          <SimpleForm toolbar={!isRo ? <CustomToolbar /> : false}>
            <TextInput multiline fullWidth rows={30} source="emails" type={'text'} disabled={isRo} />
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default WaitlistConfig;
