// in src/Dashboard.js
import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { StatsList } from '../StatsList';

const Dashboard = () => (
  <Card>
    <CardHeader title="Welcome to Bits Credit Card administration" />
    <CardContent>
      <StatsList />
    </CardContent>
  </Card>
);
export default Dashboard;
