import React from 'react';
import { Card, CardContent } from '@mui/material';
import { MarketingTaskAction } from './Marketing';

const TasksConfig: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <MarketingTaskAction />
      </CardContent>
    </Card>
  );
};

export default TasksConfig;
