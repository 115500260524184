import { Show, Tab, TabbedShowLayout } from 'react-admin';
import ApiConfig from '../ApiConfig';
import CreditDecisionConfig from '../CreditDecisionConfig';
import WaitlistConfig from '../WaitlistConfig';
import { useResourceAccess } from '../../hooks/useAccess';
import TasksConfig from '../TasksConfig';

export const SettingsEdit = () => {
  const { SettingsDetailsView } = useResourceAccess();
  if (!SettingsDetailsView()) {
    return null;
  }

  return (
    <Show resource="config" id={''} actions={false} title={'Settings'}>
      <TabbedShowLayout>
        <Tab label="Credit Card">
          <ApiConfig />
        </Tab>
        <Tab label="Credit Decision">
          <CreditDecisionConfig />
        </Tab>
        <Tab label="Waitlist">
          <WaitlistConfig />
        </Tab>
        <Tab label="Tasks">
          <TasksConfig />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SettingsEdit;
